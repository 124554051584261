import React from 'react'
import { Text, View } from 'styles/reactnative'
import { CImage } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { DepositCoinListWrapper } from '../../styles'
import { useFetcherPublic } from '../../../../hooks/fetcher'
import { useDepositStore } from '../../../../hooks/deposit/store'

const DepositCoinLists = ({ setModal }) => {
	const { data: res } = useFetcherPublic('/exchanges/coins')

	const data = [
		{
			symbol: 'KRW',
			name: 'USD',
			network: 'TRC20',
			image: 'https://cdn.coindeskkorea.com//news/photo/202202/77671_18637_544.png',
		},
	]

	// eslint-disable-next-line
	const tempdata = res?.result?.result?.filter(
		(item: any) => item.deposit == 1
	)

	if (tempdata?.length > 0) data.push(...tempdata)

	const { data: depositStore, mutate } = useDepositStore({
		network: 'TRC20',
		coin: 'KRW',
	})

	return (
		<DepositCoinListWrapper>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					width: '100%',
					height: '100%',
				}}>
				{data?.map((item: any, index) => (
					<View
						key={index.toString()}
						onClick={() => {
							mutate({ ...depositStore, coin: item?.symbol })
							setModal(true)
						}}
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
							padding: 5,
							cursor: 'pointer',
						}}>
						<View style={{ flexDirection: 'row' }}>
							<CImage
								src={`${item?.image}`}
								alt="logo"
								width={20}
								height={20}
								resizeMode={'cover'}
								style={{}}
							/>
							<Text
								size={14}
								style={{
									marginLeft: 5,
									color: COLORS.lightGray,
								}}>
								{item?.symbol == 'KRW' ?'USD': item?.symbol}
							</Text>
						</View>
						<Text size={14} style={{ color: COLORS.lightGray }}>
							{item?.name}
						</Text>
					</View>
				))}
			</View>
		</DepositCoinListWrapper>
	)
}

export default DepositCoinLists
