import React from 'react'
import KrwPrice from '../../components/KrwPrice'
import EntryTime from '../../components/EntryTime'
import BankInfo from '../../components/BankInfo'
import { Radio, Space, Tag } from 'antd'
import TableAPI from '../../components/TableAPI'
import { useTradeModalStore } from '../../hooks/trade/modalStore'
import { COLORS } from '../../constants'

const DWList = () => {
	const { data: modalStore, mutate: mutateModalStore } = useTradeModalStore()

	const [refresh, setRefresh] = React.useState(false)

	React.useEffect(() => {
		setRefresh(!refresh)
	}, [])

	return (
		<TableAPI
			title={
				<Space.Compact>
					<Radio.Group
						value={modalStore?.depositType || '*'}
						buttonStyle="solid"
						onChange={e => {
							mutateModalStore({
								...modalStore,
								depositType: e.target.value,
							})
						}}>
						<Radio.Button value="*">All</Radio.Button>
						<Radio.Button value="D">Deposit</Radio.Button>
						<Radio.Button value="W">Withdraw</Radio.Button>
					</Radio.Group>
				</Space.Compact>
			}
			defaultMaxData={50}
			stylewidth={'100%'}
			height={650}
			width={500}
			columns={() => [
				{
					title: 'Date',
					dataIndex: 'created_at',
					align: 'center',
					render: (text, record) => {
						return (
							<EntryTime
								time={record?.created_at}
								style={{ color: 'black' }}
								offset={9}
							/>
						)
					},
				},
				{
					title: 'Amount',
					dataIndex: 'amount',
					align: 'center',
					render: (text, record) => {
						return (
							<KrwPrice
								price={
									record?.type == 'W'
										? -Math.abs(text)
										: Math.abs(text)
								}
								krwprice={record?.krwprice}
							/>
						)
					},
				},
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
					render: (text, record) => {
						return <div>{text}</div>
					},
				},
				{
					title: 'STATUS',
					dataIndex: 'process',
					align: 'center',
					render: (text, data) => (
						<div>
							{text == 1 ? (
								<Tag
									style={{
										backgroundColor: '#f79336',
										color: 'white',
									}}>
									{data?.type == 'W' ? 'Withdraw' : 'Deposit'}
									 Request
								</Tag>
							) : text == 2 ? (
								<Tag
									style={{
										backgroundColor:
											data?.type == 'W'
												? COLORS.darkGreen
												: COLORS.darkRed,
										color: 'white',
									}}>
									{data?.confirm_amount
										? `${
												data?.type == 'W'
													? 'Withdraw'
													: 'Deposit'
										  } Approved`
										: `${
												data?.type == 'W'
													? '출금'
													: 'Deposit'
										  } Approved`}
								</Tag>
							) : text == 3 ? (
								<Tag
									style={{
										backgroundColor: '#444',
										color: 'white',
									}}>
									{data?.type == 'W'
										? 'Withdraw Review'
										: 'Deposit Review'}
								</Tag>
							) : text == 4 ? (
								<Tag style={{ color: '#F05551' }}>
									{data?.type == 'W'
										? 'Withdraw Rejected'
										: 'Deposit Rejected'}
								</Tag>
							) : (
								<Tag>
									{data?.type == 'W' ? 'Withdraw' : 'Deposit'}
									 Canceled
								</Tag>
							)}
						</div>
					),
				},
				{
					title: 'Confirm Date',
					dataIndex: 'confirm_at',
					align: 'center',
					render: (text, record) => {
						return (
							<EntryTime
								time={record?.confirm_at}
								style={{ color: 'black' }}
								offset={9}
							/>
						)
					},
				},
				{
					title: 'Confirm Amount',
					dataIndex: 'confirm_amount',
					align: 'center',
					render: (text, record) => {
						return (
							<KrwPrice
								theme={false}
								price={
									record?.type == 'W'
										? -Math.abs(text)
										: Math.abs(text)
								}
							/>
						)
					},
				},
				{
					title: 'Bank',
					dataIndex: 'bankaccount',
					align: 'center',
					render: (text, record) => {
						return <BankInfo text={text == 'undefined/undefined/undefined' ? '' : text} theme={false} />
					},
				},
			]}
			apikey={
				'/cash/list?type=' +
				(modalStore?.depositType || '*') +
				'&t=' +
				(refresh ? 1 : 0)
			}
		/>
	)
}

export default DWList
