import API from '../../../../../../utils/api'
import { CHARTAPI } from '../../../../../constants/TRADEAPIKEYS'

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getLanguageFromURL = () => {
	const regex = new RegExp('[\\?&]lang=([^&#]*)')
	const results = regex.exec(window.location.search)
	return results === null
		? null
		: decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const widgetOptions = (
	tvWidgetRef,
	paris,
	ep_id,
	updatechart,
	mutateUpdateChart,
	tab,
	lang,
	isDarkMode
) => {
	const datafeed = new window.Datafeeds.UDFCompatibleDatafeed(CHARTAPI)

	const resolutionIntervalMap = interval => {
		let resolutionsToIntervals = {
			'1m': '1',
			'3m': '3',
			'5m': '5',
			'15m': '15',
			'30m': '30',
			'1h': '60',
			'2h': '120',
			'4h': '240',
			'6h': '360',
			'8h': '480',
			'12h': '720',
			'1d': '1d',
			'1w': '1w',
			'1M': '1M',
		}
		return resolutionsToIntervals[interval]
	}

	lang = lang?.split('-')[0]


	datafeed.subscribeBars = function (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback
	) {
		const update = {
			datafeed: datafeed,
			updatebars: function (bars) {
				onRealtimeCallback(bars)
			},
		}
		mutateUpdateChart(update)
	}
	return {
		symbol: paris || 'BTC/USDT',
		datafeed: datafeed,
		interval: resolutionIntervalMap(tab) || '1',
		container: 'tv_chart_container',
		library_path: '/charting_library/',
		locale:'en',
		auto_save_delay: 1,
		load_last_chart: true,
		disabled_features: [
			'timeframes_toolbar',
			'volume_force_overlay',
			'show_logo_on_all_charts',
			'caption_buttons_text_if_possible',
			'header_compare',
			'compare_symbol',
			'header_widget_dom_node',
			'header_undo_redo',
			'header_interval_dialog_button',
			'border_around_the_chart',
			'show_interval_dialog_on_key_press',
			'study_templates',
			'use_localstorage_for_settings',
			'header_settings',
			'header_resolutions',
			//mobile && 'left_toolbar',
		],
		enabled_features: ['countdown'],
		request_only_visible_range_on_reset: true,
		studies_overrides: {
			'volume.volume.color.0': '#007EDA',
			'volume.volume.color.1': '#F05551',
		},
		save_load_adapter: {
			charts: [] as any,
			studyTemplates: [] as any,
			drawingTemplates: [] as any,
			chartTemplates: [] as any,

			saveStorage() {
				window.localStorage.setItem(
					'tv-charts',
					JSON.stringify(this.charts)
				)
			},

			saveStorageTemplate() {
				console.log('saveStorageTemplate', this.studyTemplates)

				window.localStorage.setItem(
					'tv-chart-templates',
					JSON.stringify(this.chartTemplates)
				)
				window.localStorage.setItem(
					'tv-study-templates',
					JSON.stringify(this.studyTemplates)
				)
				window.localStorage.setItem(
					'tv-drawing-templates',
					JSON.stringify(this.drawingTemplates)
				)
			},

			getAllCharts: function () {
				const charttemp = window.localStorage.getItem('tv-charts')
				if (charttemp) {
					this.charts = JSON.parse(charttemp)
				}
				return Promise.resolve(this.charts)
			},

			removeChart: function (id) {
				const _this = this
				setTimeout(() => {
					_this.saveStorage()
				}, 1000)

				for (var i = 0; i < this.charts.length; ++i) {
					if (this.charts[i].id === id) {
						console.log('DELETE22222', id)
						this.charts.splice(i, 1)
						return Promise.resolve()
					}
				}

				return Promise.reject()
			},

			saveChart: function (chartData: any) {
				if (!chartData.id) {
					chartData.id = Math.random().toString()
				} else {
					this.removeChart(chartData.id)
				}

				this.charts.push(chartData)

				this.saveStorage()

				return Promise.resolve(chartData.id)
			},

			getChartContent: function (id) {
				for (var i = 0; i < this.charts.length; ++i) {
					if (this.charts[i].id === id) {
						return Promise.resolve(this.charts[i].content)
					}
				}
				return Promise.reject()
			},

			removeStudyTemplate: function (studyTemplateData: any) {
				const _this = this
				setTimeout(() => {
					_this.saveStorageTemplate()
				}, 1000)

				for (var i = 0; i < this.studyTemplates.length; ++i) {
					if (
						this.studyTemplates[i].name === studyTemplateData.name
					) {
						this.studyTemplates.splice(i, 1)
						return Promise.resolve()
					}
				}
				return Promise.reject()
			},

			getStudyTemplateContent: function (studyTemplateData) {
				for (var i = 0; i < this.studyTemplates.length; ++i) {
					if (
						this.studyTemplates[i].name === studyTemplateData.name
					) {
						return Promise.resolve(this.studyTemplates[i])
					}
				}
				return Promise.reject()
			},

			saveStudyTemplate: function (studyTemplateData) {
				for (var i = 0; i < this.studyTemplates.length; ++i) {
					if (
						this.studyTemplates[i].name === studyTemplateData.name
					) {
						this.studyTemplates.splice(i, 1)
						break
					}
				}

				this.studyTemplates.push(studyTemplateData)
				this.saveStorageTemplate()
				console.log('saveStudyTemplate', studyTemplateData)

				return Promise.resolve()
			},

			getAllStudyTemplates: function () {
				const studytemp =
					window.localStorage.getItem('tv-study-templates')
				this.studyTemplates = studytemp ? JSON.parse(studytemp) : []

				console.log('getAllStudyTemplates', this.studyTemplates)
				return Promise.resolve(this.studyTemplates)
			},

			removeDrawingTemplate: function (toolName, templateName) {
				const _this = this
				setTimeout(() => {
					_this.saveStorageTemplate()
				}, 1000)
				for (var i = 0; i < this.drawingTemplates.length; ++i) {
					if (this.drawingTemplates[i].name === templateName) {
						this.drawingTemplates.splice(i, 1)
						return Promise.resolve()
					}
				}

				return Promise.reject()
			},

			loadDrawingTemplate: function (toolName, templateName) {
				console.log('loadDrawingTemplate', toolName, templateName)
				const drawingtemp = window.localStorage.getItem(
					'tv-drawing-templates'
				)
				this.drawingTemplates = drawingtemp
					? JSON.parse(drawingtemp)
					: []

				for (var i = 0; i < this.drawingTemplates.length; ++i) {
					if (this.drawingTemplates[i].name === templateName) {
						return Promise.resolve(this.drawingTemplates[i].content)
					}
				}
				return Promise.reject()
			},

			saveDrawingTemplate: function (toolName, templateName, content) {
				for (var i = 0; i < this.drawingTemplates.length; ++i) {
					if (this.drawingTemplates[i].name === templateName) {
						this.drawingTemplates.splice(i, 1)
						break
					}
				}

				this.drawingTemplates.push({
					name: templateName,
					content: content,
				})

				this.saveStorageTemplate()
				return Promise.resolve()
			},

			getDrawingTemplates: function () {
				console.log('getDrawingTemplates')
				const drawingtemp = window.localStorage.getItem(
					'tv-drawing-templates'
				)
				this.drawingTemplates = drawingtemp
					? JSON.parse(drawingtemp)
					: []

				return Promise.resolve(
					this.drawingTemplates.map(function (template) {
						return template.name
					})
				)
			},
		},
		overrides: {
			'candleStyle.upColor': '#F05551',
			'candleStyle.downColor': '#007EDA',
			'candleStyle.wickUpColor': '#F05551',
			'candleStyle.wickDownColor': '#007EDA',
			'candleStyle.borderUpColor': '#F05551',
			'candleStyle.borderDownColor': '#007EDA',
			'candleStyle.volumeUpColor': '#F05551',
			'candleStyle.volumeDownColor': '#007EDA',
			'plotLineColor.priceLine.long': '#F05551',
			'plotLineWidth.priceLine.long': 2,
			'plotLineColor.priceLine.short': '#007EDA',
			'plotLineWidth.priceLine.short': 2,
			'mainSeriesProperties.showCountdown': true,
		},

		client_id: 'tradingview',
		timezone: 'Asia/Seoul',
		user_id: 'ccoin',
		fullscreen: false,
		autosize: true,
		theme: isDarkMode ? 'light' : 'dark',
		volume_precision: 0,
	}
}
