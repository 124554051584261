import React from 'react'
import Select from 'react-select'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { getSetting, useSetting } from 'app/hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from 'app/constants/SETTING'
import { useFetcherPublic } from 'app/hooks/fetcher'

const CurrencyBox = () => {
	const { t } = useTranslation()

	const { data: setting, updateSetting } = useSetting(SETTING_TYPE_SYSTEM)
	const currency = getSetting(setting, 'CURRENCY', 'USD')

	const { data: result } = useFetcherPublic('/system/currency')
	const currencyOptions = result?.result?.result?.map((item: any) => ({
		value: item.code,
		label: item.name,
	}))

	const customStyles = {
		container: (provided: any) => ({
			...provided,
			width: '100%',
		}),
	}

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
				padding: 10,
			}}>
			<Text size={18} style={{ fontWeight: '500' }}>
				{t('currency')}
			</Text>
			<View
				style={{ marginTop: 20, width: 300, alignItems: 'flex-start' }}>
				<Text size={16} style={{ fontWeight: '400' }}>
					{t('equivalentCurrency')}
				</Text>
				<View style={{ width: '100%', marginTop: 10 }}>
					<Select
						defaultValue={currencyOptions?.find(
							(item: any) => item.value === currency
						)}
						options={currencyOptions}
						styles={customStyles}
						onChange={({ value }: any) => {
							console.log(value)
							updateSetting('CURRENCY', value)
						}}
					/>
				</View>
			</View>
		</View>
	)
}

export default React.memo(CurrencyBox)
