export const COLORS = {
	// base colors
	// primary:
	// 	'linear-gradient(45deg, rgba(116, 56, 255, 1), rgba(56, 96, 255, 1))',
	primary: '#cc2a8e',
	primaryDark: '#f78400', //#e57d1d44',
	primaryOpacity:
		'linear-gradient(0deg,hsla(0,0%,100%,.9),hsla(0,0%,100%,.9)),#f7a600',
	secondary: '#0095FF',
	backgroundColor: '#F5F7FA',

	BTN_NORMAL: '#FFFFFF',
	BTN_HOVER: '#f2ba00',
	inputBackground: '#2c2c33',
	borderColor: '#444',
	HeaderBarBackground: '#17181E',

	// colors
	black: '#1C1C1C',
	white: '#FFFFFF',
	grayWhite: '#F1F1F1',
	greyWhite: '#E9E9E9',
	lightGray: '#888B90',
	lightGray1: '#adb1b8',
	lightGray2: '#EEE',
	lightGray3: '#D4D5D6',
	lightGray4: '#C4C4C4',
	lightGray5: '#7D7E84',
	grey: '#202020',
	grey1: '#202124',
	gray: '#2D3038',
	gray1: '#282C35',
	gray2: '#404347',
	darkRed: '#DA2B41',
	lightRed: '#007EDA',
	darkBlue: '#121627 ',
	lightBlue: '#00ADF2',
	darkGreen: '#1c3936',
	lightGreen: '#F05551',
	green: '#24ae64',
	bgGreen: 'rgba(36,174,100,.08)',
	themePurple: '#E5233C',
	loginbgb: '#160b27',
	loginbgw: '#ffffff',
	grad1: '#ff39b3',
	grad2: '#d431fd',
}

export const SIZES = {
	// base colors
	primary: '14px',
	secondary: '12px',
	h1: '30px',
	h2: '24px',
	h3: '20px',
	h4: '18px',
	h5: '16px',
	h6: '14px',
	h7: '12px',
	h8: '10px',
	body1: '14px',
	body2: '12px',
	body3: '10px',
	body4: '8px',
	body5: '6px',
}

export const FONTS = {
	h1: { fontFamily: 'Spoqa Han Sans Neo, sans-serif', fontSize: SIZES.h1 },
	h2: { fontFamily: 'Spoqa Han Sans Neo, sans-serif', fontSize: SIZES.h2 },
	h3: { fontFamily: 'Spoqa Han Sans Neo, sans-serif', fontSize: SIZES.h3 },
	h4: { fontFamily: 'Spoqa Han Sans Neo, sans-serif', fontSize: SIZES.h4 },
	h5: { fontFamily: 'Spoqa Han Sans Neo, sans-serif', fontSize: SIZES.h5 },
	h6: { fontFamily: 'Spoqa Han Sans Neo, sans-serif', fontSize: SIZES.h6 },
	h7: { fontFamily: 'Spoqa Han Sans Neo, sans-serif', fontSize: SIZES.h7 },
	h8: { fontFamily: 'Spoqa Han Sans Neo, sans-serif', fontSize: SIZES.h8 },
	body1: {
		fontFamily: 'Spoqa Han Sans Neo, sans-serif',
		fontSize: SIZES.body1,
	},
	body2: {
		fontFamily: 'Spoqa Han Sans Neo, sans-serif',
		fontSize: SIZES.body2,
	},
	body3: {
		fontFamily: 'Spoqa Han Sans Neo, sans-serif',
		fontSize: SIZES.body3,
	},
	body4: {
		fontFamily: 'Spoqa Han Sans Neo, sans-serif',
		fontSize: SIZES.body4,
	},
	body5: {
		fontFamily: 'Spoqa Han Sans Neo, sans-serif',
		fontSize: SIZES.body5,
	},
}
