import React from 'react'
import { AboutAppViewContent } from './styles'
import { Text } from 'styles/reactnative'
import { CButton } from 'app/components/Common'
import { useTranslation } from 'react-i18next'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { COLORS } from 'app/constants'
const AboutJoin = () => {
	const navigation = useHistory()
	const { t } = useTranslation()
	return (
		<AboutAppViewContent style={{ marginBlock: 73 }}>
			<Text size={32}>{t('joinTheCryptoMovement')}</Text>
			<Text
				size={16}
				style={{
					marginTop: 14,
					lineHeight: 1.5,
					fontWeight: '300',
				}}>
				{t('joinTheCryptoMovementContent')}
			</Text>
			<CButton
				title={t('signUpNow')}
				onClick={() => {
					goTo(navigation, '/signup')
				}}
				style={{
					marginTop: 30,
					color: COLORS.white,
					height: 35,
					paddingInline: 15,
					borderRadius: 3,
					background:
					'linear-gradient(to bottom, #385dff 0%, #387eff 100%)',
				}}
			/>
		</AboutAppViewContent>
	)
}

export default AboutJoin
