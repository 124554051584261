import React from 'react'

import { View } from 'styles/reactnative'
import AuthButtonLists from '../AuthButtonLists'
import { Modal } from 'antd'
import DWList from '../../DWList'
import CalcList from '../../CalcList'

const AssetsFooter = () => {
	const [show, setShow] = React.useState(false)
	const [show2, setShow2] = React.useState(false)
	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				marginTop: 20,
			}}>
			<AuthButtonLists
				title={'recentDepositWithdrawalHistory'}
				action={() => {
					setShow(true)
				}}
			/>

			<AuthButtonLists
				title={'PNLHistory'}
				action={() => {
					setShow2(true)
				}}
			/>

			<Modal
				width={1400}
				open={show}
				onOk={() => {
					setShow(false)
				}}
				onCancel={() => {
					setShow(false)
				}}>
				<DWList />
			</Modal>
			<Modal
				width={1400}
				open={show2}
				onOk={() => {
					setShow2(false)
				}}
				onCancel={() => {
					setShow2(false)
				}}>
				<CalcList />
			</Modal>
		</View>
	)
}

export default AssetsFooter
