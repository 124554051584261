import React, { useEffect, useState } from 'react'
import { SupportSection } from '../styles'
import SupportTitle from '../SupportTitle'
import { PageMenuTabs } from 'app/components'
import TheBitCardList from './TheBitCardList'
import { COLORS } from 'app/constants'
import API from 'utils/api'

import { Modal } from 'antd'

import cash from '../../../../assets/images/deposit-cash.png'
import coin from '../../../../assets/images/deposit-coin.png'
import withdrawalCash from '../../../../assets/images/withdrawal-cash.png'
import withdrawalCoin from '../../../../assets/images/withdrawal-coin.png'
import guide from '../../../../assets/images/stock-guide.png'
import tax from '../../../../assets/images/stock-tax.png'
import position from '../../../../assets/images/stock-position.png'

const all = 'all'
const perpetual = 'perpetual'
const derivatives = 'derivatives'
const spot = 'spot'
const TheBitLearn = () => {
	const [tab, setTab] = useState(all)
	const tabs = [all, perpetual, derivatives, spot]

	const [modal, setModal] = useState(false)
	const [item, setItem] = useState({} as any) // [content, image]

	let images:any = [
		cash,
		coin,
		withdrawalCash,
		withdrawalCoin,
		guide,
		tax,
		position,
	]

	const openModal = i => {
		setItem(i)
		setModal(true)
	}

	const [data, setData] = useState([])

	// response content and image
	const fetchLearnData = async () => {
		API.get('/thebitlearn', null, {}).then(res => {
			if (res?.result?.success) {
				setData(res.result.result.list)
			}
		})
	}
	useEffect(() => {
		// fetchLearnData();
	}, [])
	const dummyData = [
		// { content: '입금방법 (코인)', image: '/images/manual/depo.png' },
		// { content: '입금방법 (현금)', image: '/images/manual/depo2.png' },
		// { content: '출금방법 (코인)', image: '/images/manual/with1.png' },
		// { content: '출금방법 (현금)', image: '/images/manual/with2.png' },
		// { content: '선물거래 가이드', image: '/images/manual/guide.png' },
		// {
		// 	content: '선물거래 수익 및 수수료의 계산방법',
		// 	image: '/images/manual/fee.png',
		// },
		// {
		// 	content: '선물거래 포지션 및 자금 확인 방법',
		// 	image: '/images/manual/asset.png',
		// },
	]
	return (
		<SupportSection>
			<SupportTitle
				title={'theBitLearn'}
				content={'theBitHeaderContent'}
			/>
			<TheBitCardList data={dummyData} openModal={openModal} images={images}/>

			<Modal
				title={item?.content}
				open={modal}
				width={'fit-content'}
				footer={null}
				centered={true}
				onCancel={() => {
					setModal(false)
				}}>
				{item?.image && (
					<img
						alt={item?.content}
						src={item?.image}
						style={{ width: '100%', maxWidth: 'fit-content' }}
					/>
				)}
			</Modal>
		</SupportSection>
	)
}

export default TheBitLearn
