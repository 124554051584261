import { COLORS } from 'app/constants'
import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from '../../theme/ThemeProvider'
import { useWindowDimensions } from '../hooks'
import { useFetcherPublic } from '../hooks/fetcher'
import { getSetting, useSetting } from '../hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from '../constants/SETTING'

const KrwPrice = ({
	price = 0,
	dp = undefined,
	color = true,
	krw = true,
	margin = false,
	ratemargin = 0,
	theme = true,
	equiv = false,
	equivcenter = false,
	krwprice = undefined
}: any) => {
	const { isDarkMode, theme: themecolor } = useContext(ThemeContext)
	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const settingCurrency = getSetting(setting, 'CURRENCY', 'USD')

	const blueColor = COLORS.lightRed
	const redColor = COLORS.lightGreen

	const currencyList = useFetcherPublic('/system/currency')

	let equivusd = 1
	if (equiv) {
		equivusd =
			currencyList?.data?.result?.result?.find(
				(item: any) => item?.code == settingCurrency
			)?.price || 1
	}

	const { width } = useWindowDimensions()
	return (
		<View
			style={{
				alignItems: 'flex-end',
				fontSize: width < 1300 ? '0.8em' : '0.9em',
				fontWeight: 500,
			}}>
			{equiv ? (
				<View style={{ alignItems: 'flex-end' }}>
					<Text
						style={{
							justifyContent: 'flex-end',
							marginRight: margin ? 10 : 0,
							fontSize: width < 1300 ? '0.8em' : '1em',
							fontWeight: 500,
							whiteSpace: 'nowrap',
							letterSpacing: -0.6,
							color: color
								? price < 0
									? blueColor
									: price > 0
									? redColor
									: theme
									? themecolor.COLORS.textw
									: 'black'
								: theme
								? themecolor.COLORS.textw
								: 'black',
						}}>
						{price
							? BigNumber(+price).toFormat(dp || 2) +
							  (krw ? ' USDT' : '')
							: '-'}
					</Text>
					{ratemargin > 0 && (
						<Text
							style={{
								fontSize: width < 1300 ? '0.75em' : '0.9em',
								whiteSpace: 'nowrap',
								color: color
									? price < 0
										? blueColor
										: price > 0
										? redColor
										: theme
										? themecolor.COLORS.textw
										: 'black'
									: theme
									? themecolor.COLORS.textw
									: 'black',
							}}>
							(
							{BigNumber(+price)
								.dividedBy(ratemargin)
								.multipliedBy(100)
								.toFormat(2)}
							%)
						</Text>
					)}
					<Text
						style={{
							justifyContent: equivcenter ? 'flex-start' : 'flex-end',
							marginRight: margin ? 10 : 0,
							fontSize: '0.85em',
							fontWeight: 500,
							whiteSpace: 'nowrap',
							letterSpacing: -0.6,
							color: themecolor.COLORS.textsilver,
						}}>
						{price
							? '≒ ' +
							  BigNumber(price)
									.multipliedBy(equivusd)
									.toFormat(0) +
							  ' ' +
							  settingCurrency
							: '-'}
					</Text>
				</View>
			) : (
				<View style={{ alignItems: 'flex-end' }}>
					<Text
						style={{
							justifyContent: 'flex-end',
							marginRight: margin ? 10 : 0,
							fontSize: width < 1300 ? '0.8em' : '1em',
							fontWeight: 500,
							whiteSpace: 'nowrap',
							letterSpacing: -0.6,
							color: color
								? price < 0
									? blueColor
									: price > 0
									? redColor
									: theme
									? themecolor.COLORS.textw
									: 'black'
								: theme
								? themecolor.COLORS.textw
								: 'black',
						}}>
						{price
							? BigNumber(price).toFormat(dp || 2) +
							  (krw ? ' USDT' : '')
							: ''}
					</Text>
					{ratemargin > 0 && (
						<Text
							style={{
								fontSize: width < 1300 ? '0.75em' : '0.9em',
								whiteSpace: 'nowrap',
								color: color
									? price < 0
										? blueColor
										: price > 0
										? redColor
										: theme
										? themecolor.COLORS.textw
										: 'black'
									: theme
									? themecolor.COLORS.textw
									: 'black',
							}}>
							(
							{BigNumber(price)
								.dividedBy(ratemargin)
								.multipliedBy(100)
								.toFormat(2)}
							%)
						</Text>
					)}
				</View>
			)}
		</View>
	)
}

export default React.memo(KrwPrice)
