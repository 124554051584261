import React, { useContext, useEffect } from 'react'

import { useToken } from 'app/hooks'
import { AuthCheckText } from 'app/components'

import { TradeDetailListSection, TradeText } from './styles'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import { Button, Space, Switch, Table } from 'antd'
import { goTo } from '../../../../hooks/navigation'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import { Text, View } from '../../../../../styles/reactnative'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { COLORS } from '../../../../constants'
import useTradeStore from '../../../../hooks/trade/tradeStore'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import Closedby from './closedBy'
import KrwPrice from '../../../../components/KrwPrice'
import NoData from '../../../../components/Common/CFlatList/NoData'
import CRenderEmpty from '../../../../components/CRenderEmpty'
import ShareModal from '../../../ShareModal'
import { IconShare2 } from '@tabler/icons-react'
import { getSetting, useSetting } from '../../../../hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from '../../../../constants/SETTING'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import ContractsColumn from '../Columns/ContractsColumn'

const TradePositionsTable = ({ parentHeight = 300 }) => {
	const { data: token } = useToken()
	const { textw } = useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const nav = useHistory()

	const [shareData, setShareData] = React.useState<any>(null)

	const [closeAll, setCloseAll] = React.useState(false)

	const [refresh, setRefresh] = React.useState(false)

	const { mutate: mutateModalStore, data: modalStore } = useTradeModalStore()

	const { data: tradeStore } = useTradeStore()

	const { data: setting, updateSetting } = useSetting(SETTING_TYPE_SYSTEM)
	const equivshow = getSetting(setting, 'EQUIVSHO', 1) == 1 ? true : false

	const { getPrice } = useIndexPrice(tradeStore?.ep_id || 0)
	const { EPs } = useExchangeStore()
	const { data: pos } = useTradePosition()

	let list = pos

	if (modalStore?.iscurrent) {
		list = pos?.filter(
			(item: any) => item?.exchange_pairs_id == tradeStore?.ep_id
		)
	}

	useEffect(() => {
		setRefresh(true)
		setTimeout(() => {
			setRefresh(false)
		}, 100)
	}, [list?.length])

	/*
	const renderItem = (item: any, index: any) => {
		return (
			<TradeDetailListItem
				item={item}
				index={index}
				dataType={dataType.length}
			/>
		)
	}
*/
	return (
		<TradeDetailListSection>
			{token ? null : <AuthCheckText />}

			{list?.length ? (
				<Table
					showHeader={true}
					bordered={false}
					loading={refresh}
					dataSource={refresh ? [] : list}
					columns={[
						{
							title: t('contracts') + '',
							dataIndex: 'contracts',
							align: 'center',
							key: 'contracts',
							render: (text: any, item) => (
								<ContractsColumn item={item} />
							),
						},
						{
							title: t('qty') + '',
							dataIndex: 'amount',
							key: 'amount',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								const coin = EP?.pairs?.split('/')[0]
								return (
									<TradeText
										style={{
											letterSpacing: 0,
											minWidth: 90,
											whiteSpace: 'nowrap',
											lineHeight: 1,
											flexDirection: 'column',
											alignContent: 'center',
											alignItems: 'flex-end',
										}}>
										{BigNumber(item?.amount)
											.multipliedBy(item?.leverage)
											.toFormat(
												EP?.dp2 || EP?.dp || 3
											)}{' '}
										{coin}
									</TradeText>
								)
							},
						},
						{
							title: t('entryPrice') + '',
							dataIndex: 'open_price',
							key: 'open_price',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)

								return (
									<TradeText
										style={{
											letterSpacing: 0,
											minWidth: 90,
											lineHeight: 1,
											flexDirection: 'column',
											alignContent: 'center',
											alignItems: 'flex-end',
										}}>
										{BigNumber(item?.price).toFormat(
											EP?.dp || 3
										)}
									</TradeText>
								)
							},
						},
						{
							title: t('markPrice') + '',
							dataIndex: 'mark_price',
							key: 'mark_price',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								return (
									<TradeText
										style={{
											letterSpacing: 0,
											minWidth: 90,
											lineHeight: 1,
											flexDirection: 'column',
											alignContent: 'center',
											alignItems: 'flex-end',
										}}>
										{BigNumber(
											getPrice(item?.exchange_pairs_id)
										).toFormat(EP?.dp || 3)}
									</TradeText>
								)
							},
						},
						{
							title: t('liqPrice') + '',
							dataIndex: 'liquidation_price',
							key: 'liquidation_price',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								return (
									<TradeText
										style={{
											letterSpacing: 0,
											minWidth: 90,
											lineHeight: 1,
											flexDirection: 'column',
											alignContent: 'center',
											alignItems: 'flex-end',
										}}>
										{BigNumber(item?.liquid_price).toFormat(
											EP?.dp || 3
										)}
									</TradeText>
								)
							},
						},
						{
							title: t('margin') + '',
							dataIndex: 'margin',
							key: 'margin',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								const currency = EP?.pairs?.split('/')[1]
								return (
									<TradeText
										style={{
											letterSpacing: 0,
											minWidth: 90,
											lineHeight: 1,
											flexDirection: 'column',
											alignContent: 'center',
											alignItems: 'flex-end',
										}}>
										<KrwPrice
											price={item?.margin}
											currency={currency}
											color={false}
											equiv={true}
											dp={3}
										/>
									</TradeText>
								)
							},
						},
						{
							title: t('unrealizedplpercent') + '',
							dataIndex: 'unrealized_pl_percent',
							key: 'unrealized_pl_percent',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								const currency = EP?.pairs?.split('/')[1]
								let pnl = 0

								if (item?.long_short === 'L') {
									pnl = BigNumber(
										getPrice(item?.exchange_pairs_id)
									)
										.minus(item?.price)
										.multipliedBy(item?.amount)
										.multipliedBy(item?.leverage)
										.toNumber()
								} else {
									pnl = BigNumber(item?.price)
										.minus(
											getPrice(item?.exchange_pairs_id)
										)
										.multipliedBy(item?.amount)
										.multipliedBy(item?.leverage)
										.toNumber()
								}

								return (
									<View style={{ flexDirection: 'row' }}>
										<TradeText
											style={{
												letterSpacing: 0,
												minWidth: 90,
												lineHeight: 0.9,
												flexDirection: 'column',
												alignContent: 'center',
												alignItems: 'flex-end',
											}}>
											<KrwPrice
												price={pnl}
												equiv={equivshow}
												ratemargin={item?.margin}
												currency={currency}
												color={true}
												dp={3}
											/>
											<CRenderEmpty
												id={item?.exchange_pairs_id}
											/>
										</TradeText>

										<Button
											type="ghost"
											size="small"
											icon={
												<IconShare2
													color={
														pnl > 0
															? COLORS.lightGreen
															: pnl < 0
															? COLORS.lightRed
															: textw
													}
													size={16}
												/>
											}
											onClick={() => {
												setShareData(null)
												setTimeout(() => {
													setShareData(item)
												}, 100)
											}}
										/>
									</View>
								)
							},
						},
						{
							title: t('realizedpl') + '',
							dataIndex: 'realized_pl',
							key: 'realized_pl',
							align: 'right',
							render: (text: any, item) => {
								const EP = EPs?.find(
									(item2: any) =>
										+item2.id == +item?.exchange_pairs_id
								)
								const currency = EP?.pairs?.split('/')[1]

								return (
									<View style={{ minWidth: 90 }}>
										<KrwPrice
											price={item?.pnl_price}
											currency={currency}
											equiv={equivshow}
											dp={EP?.dp2 || 3}
										/>
									</View>
								)
							},
						},
						{
							title: (
								<Space size={'small'}>
									<Button
										type="primary"
										size="small"
										onClick={() => {
											setCloseAll(true)
										}}>
										{t('entire')} {t('closeBy') + ''}
									</Button>
								</Space>
							),
							dataIndex: 'close_by',
							key: 'close_by',
							align: 'center',
							render: (text: any, item) => {
								return (
									<Closedby
										item={item}
										closeAll={closeAll}
										setCloseAll={setCloseAll}
										refresh={item?.exchange_pairs_id}
									/>
								)
							},
						},
					]}
					pagination={false}
					style={{ width: '100%', height: '100%' }}
					sticky={true}
					scroll={
						list?.length * 50 >= parentHeight - 100
							? { x: true, y: parentHeight - 100 }
							: { x: true }
					}
					size="small"
				/>
			) : (
				<View style={{ flex: 1, height: '100%' }}>
					<NoData title={'noOrderYet'} />
				</View>
			)}
			<ShareModal shareData={shareData} setShareData={setShareData} />
		</TradeDetailListSection>
	)
}

export default TradePositionsTable
