import React, { useState, useContext } from 'react'
import { IconChevronDown } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

const TransferFromDropdown = ({
	transferFrom,
	setTransferFrom,
	transferTo,
	action,
}) => {
	const { t } = useTranslation()
	const { gray, textw } = useContext(ThemeContext).theme.COLORS
	const [display, setDisplay] = useState<boolean>(false)
	let list = ['spotwallet', 'futurewallet']

	if (transferTo == 'globalwallet') {
		setTransferFrom('spotwallet')
	}

	return (
		<View style={{ width: '45%' }}>
			<Text
				size={14}
				style={{
					paddingBottom: 5,
					alignSelf: 'flex-start',
				}}>
				{t('from')}
			</Text>
			<View
				style={{
					width: '100%',
					height: 35,
					background: COLORS.grey1,
					borderRadius: 5,
				}}>
				<CButton
					title={t(transferFrom)}
					onClick={() => setDisplay(!display)}
					icon={<IconChevronDown size={10} color={textw} />}
					style={{
						height: '100%',
						width: '100%',
						color: textw,
						justifyContent: 'space-between',
						background: gray,
					}}
				/>
				{display ? (
					<View
						style={{
							position: 'absolute',
							width: '41%',
							background: gray,
							zIndex: 99,
							marginTop: list.length * 50 + 10 / list.length,
						}}>
						{list.map((item, index) => (
							<CButton
								key={index.toString()}
								title={t(item)}
								onClick={() => {
									action(item)
									setTransferFrom(item)
									setDisplay(!display)
								}}
								backgroundHover={COLORS.lightGray3}
								style={{
									width: '100%',
									alignItems: 'flex-start',
									padding: 10,
									cursor: 'pointer',
									color:
										transferFrom === item
											? COLORS.secondary
											: COLORS.lightGray1,
								}}
							/>
						))}
					</View>
				) : null}
			</View>
		</View>
	)
}

export default TransferFromDropdown
