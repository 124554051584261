import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { IconCopy } from '@tabler/icons'
import { CButton, CInput } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { useDepositStore } from '../../../hooks/deposit/store'
import { useFetcher } from '../../../hooks/fetcher'
import { useMyProfile } from '../../../hooks/user/myprofile'

const WithdrawWalletAddress = ({ data, setData }) => {
	const { t } = useTranslation()

	const { data: profile } = useMyProfile()

	const { data: depositStore } = useDepositStore({
		network: 'TRC20',
		coin: 'USDT',
	})

	const bank =
		(profile?.bank_name || '') +
		' ' +
		(profile?.bank_account || '') +
		' ' +
		(profile?.bank_owner || '')

	useEffect(() => {
		setData(bank)
	}, [profile])

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				marginTop: 20,
				justifyContent: 'flex-start',
			}}>
			{depositStore?.coin == 'KRW' ? (
				<Text>Withdraw addresss</Text>
			) : (
				<Text>{t('walletAddress')}</Text>
			)}
			<CInput
				style={{
					background: 'transparent',
					color: COLORS.secondary,
					border: 0,
					margin: 0,
				}}
				vStyle={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					border: `1px solid ${COLORS.secondary}`,
					width: '100%',
					borderRadius: 5,
					marginTop: 10,
				}}
				value={depositStore?.coin == 'KRW' ? bank : data}
				onChange={e => setData(e.target.value)}
				type={''}
				placeholder={''}
				readonly={depositStore?.coin == 'KRW' ? true : false}
			/>
		</View>
	)
}

export default WithdrawWalletAddress
