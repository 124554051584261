import { useExchangeStore } from 'app/hooks/trade/exchangeStore'
import React from 'react'
import { TradeText } from './styles'
import { goTo } from 'app/hooks/navigation'
import { useHistory } from 'react-router-dom'
import { Text } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'app/constants'

const ContractsColumn = ({ item }) => {
	const { t } = useTranslation()
	const { EPs } = useExchangeStore()
	const nav = useHistory()

	const EP = EPs?.find((item2: any) => +item2.id == +item?.exchange_pairs_id)
	return (
		<TradeText style={{ flexDirection: 'row' }}>
			<TradeText
				style={{
					flexDirection: 'column',
					cursor: 'pointer',
				}}
				onClick={() => {
					goTo(nav, '/trade/' + EP?.id)
				}}>
				<Text>{EP?.pairs}</Text>
				<Text
					style={{
						color: 'orange',
						fontSize: 10,
						whiteSpace: 'nowrap',
					}}>
					{item?.cross_iso === 'C' ? t('cross') : t('iso')}{' '}
					{item?.leverage}X
				</Text>
			</TradeText>
			<Text
				style={{
					marginLeft: 5,
					color:
						item?.long_short === 'L'
							? COLORS.lightGreen
							: COLORS.lightRed,
				}}>
				{item?.long_short === 'L' ? t('long') : t('short')}
			</Text>
		</TradeText>
	)
}

export default React.memo(ContractsColumn)