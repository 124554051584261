import React, { useContext, useEffect, useState } from 'react'
import { CButton } from '../../../../components/Common'
import { COLORS } from '../../../../constants'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'
import API from '../../../../../utils/api'
import {
	Button,
	Checkbox,
	Col,
	Divider,
	Input,
	Modal,
	Popover,
	Row,
	Select,
	Space,
	message,
} from 'antd'
import { Input as InputMobile } from 'antd-mobile'

import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import { useOrderBook } from '../../../../hooks/trade/orderBook'
import { useToken } from '../../../../hooks'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import { useIndexPrice } from '../../../../hooks/trade/indexprice'
import { useLastPrice } from '../../../../hooks/trade/lastprice'
import BigNumber from 'bignumber.js'
import { MView } from '../../../../../styles/mobilePageStyles'
import { IconEdit } from '@tabler/icons'
import { Text, View } from '../../../../../styles/reactnative'
import TPSLEDIT from '../TPSLEDIT'
import KrwPrice from '../../../../components/KrwPrice'
import { check } from 'prettier'

const ClosedBy = ({
	item,
	closeAll,
	setCloseAll,
	isMobile = false,
	refresh = false,
}) => {
	const { EPs } = useExchangeStore()
	const [EP, setEP] = useState(
		EPs?.find((item2: any) => +item2.id == +item?.exchange_pairs_id)
	)
	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()

	useEffect(() => {
		setEP(EPs?.find((item2: any) => +item2.id == +item?.exchange_pairs_id))
	}, [EPs, item])

	useEffect(() => {
		if (closeAll) {
			sendOrder('M')
			setCloseAll(false)
		}
	}, [closeAll])

	const [open, setOpen] = useState(false)
	const [limit_market, setLimitMarket] = React.useState('L')
	const [amount, setAmount] = React.useState<any>(
		new BigNumber(item?.amount).multipliedBy(item?.leverage).toNumber()
	)

	let { getPrice } = useIndexPrice(item?.exchange_pairs_id)
	let { data: lastprice } = useLastPrice(item?.exchange_pairs_id)
	const [closePrice, setClosePrice] = React.useState(
		getPrice(item?.exchange_pairs_id)
	)

	const [tp, setTp] = React.useState<any>(item?.tpsl?.[0]?.tp || 0)
	const [sl, setSl] = React.useState<any>(item?.tpsl?.[0]?.sl || 0)
	const { data: token } = useToken()
	const { mutate: mutateOrderBook } = useOrderBook(item?.exchange_pairs_id)

	const { mutate: mutatePosition } = useTradePosition()

	const { textw, bgColor } = useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()

	const { data: tradeModalStore, mutate: mutateTradeModalStore } =
		useTradeModalStore()

	const coin = EP?.pairs?.split('/')[0]
	const currency = EP?.pairs?.split('/')[1]
	let pnl = 0

	if (item?.long_short === 'L') {
		pnl = BigNumber(lastprice?.[0]?.price)
			.minus(item?.price)
			.multipliedBy(item?.amount)
			.multipliedBy(item?.leverage)
			.toNumber()
	} else {
		pnl = BigNumber(item?.price)
			.minus(lastprice?.[0]?.price)
			.multipliedBy(item?.amount)
			.multipliedBy(item?.leverage)
			.toNumber()
	}

	const sendOrder = async limitmarket => {
		let checkamount = new BigNumber(amount)
			.dividedBy(item?.leverage)
			.toNumber()

		if (item?.amount < checkamount) {
			checkamount = item?.amount
		}

		if (!amount) {
			message.error('수량을 확인해주세요.')
		}
		const orderParams = {
			ep_id: item?.exchange_pairs_id,
			main_sub: 'M',
			long_short: item?.long_short === 'L' ? 'S' : 'L',
			cross_iso: item?.cross_iso,
			leverage: item?.leverage,
			reduce_position: 1,
			post_only: 1,
			limit_market: limitmarket,
			price: closePrice,
			amount: checkamount,
		}

		const result = await API.post('/trade/order', '' + token, orderParams)

		if (result?.result?.success) {
			setTimeout(() => {
				mutateOrderBook()
				mutatePosition()
			}, 500)
			message.success(`${t('positionClosedSuccessfully')}`)
		} else {
			result?.result?.message &&
				message.error(`${t(result?.result?.message)}`)
		}
	}

	return (
		<View
			style={isMobile ? { width: '100%', flexDirection: 'column' } : {}}>
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					borderRadius: isMobile ? 5 : 0,
					backgroundColor: isMobile ? bgColor : 'transparent',
					display: isMobile
						? item?.tpsl?.length &&
						  (item?.tpsl?.[0]?.tp > 0 || item?.tpsl?.[0]?.sl > 0)
							? 'flex'
							: 'none'
						: 'auto',
					padding: isMobile ? 5 : 0,
					width: '100%',
					maxWidth: !isMobile ? 340 : '100%',
				}}>
				<Modal
					open={open}
					title={t('ordercheck') + ''}
					onCancel={() => {
						setOpen(false)
					}}
					onOk={() => {
						sendOrder(limit_market)
						setOpen(false)
					}}
					cancelText={t('cancel')}
					okText={t('confirm')}>
					<Divider />
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>Pairs</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{EP?.pairs}
						</Col>
					</Row>

					{isMobile && (
						<Row
							style={{
								borderBottom: '1px solid #efefef',
								padding: '5px 0',
							}}>
							<Col span={12}>Limit/Markets</Col>
							<Col span={12} style={{ fontWeight: 'bold' }}>
								<Space.Compact>
									<Button
										size="middle"
										type={
											limit_market == 'L'
												? 'primary'
												: 'default'
										}
										onClick={() => {
											setLimitMarket('L')
										}}>
										{t('limit')}
									</Button>
									<Button
										type={
											limit_market == 'M'
												? 'primary'
												: 'default'
										}
										size="middle"
										onClick={() => {
											setLimitMarket('M')
										}}>
										{t('market')}
									</Button>
								</Space.Compact>
							</Col>
						</Row>
					)}

					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>Price</Col>
						{isMobile ? (
							<Col span={12} style={{ fontWeight: 'bold' }}>
								<InputMobile
									defaultValue={item?.price}
									value={closePrice}
									type="number"
									style={{
										width: isMobile ? '100%' : 95,
										color: textw,
										fontSize: '1rem',
										marginLeft: 5,
									}}
									onChange={e => setClosePrice(e)}
									step={parseFloat(
										'0.' + '1'.padStart(EP?.dp || 3, '0')
									)}
								/>
							</Col>
						) : (
							<Col span={12} style={{ fontWeight: 'bold' }}>
								{limit_market == 'M'
									? getPrice(item?.exchange_pairs_id) +
									  ' (Market)'
									: closePrice}
							</Col>
						)}
					</Row>
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>Amount</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{isMobile ? (
								<InputMobile
									defaultValue={item?.amount}
									value={amount}
									type="number"
									step={0.001}
									style={{
										color: textw,
										width: isMobile ? '100%' : 95,
										fontSize: '1rem',
										marginLeft: 5,
									}}
									onChange={e => setAmount(e)}
								/>
							) : (
								<>
									{new BigNumber(amount).toNumber()} {coin}
								</>
							)}
						</Col>
					</Row>
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>Long/Short</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{t(item?.long_short === 'L' ? 'long' : 'short')}
						</Col>
					</Row>
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>Leverage</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{t(item?.cross_iso == 'C' ? 'cross' : 'iso')}{' '}
							{item?.leverage}X
						</Col>
					</Row>
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>Margin</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{item?.margin} {currency}
						</Col>
					</Row>
					<Row
						style={{
							borderBottom: '1px solid #efefef',
							padding: '5px 0',
						}}>
						<Col span={12}>Reduce Position</Col>
						<Col span={12} style={{ fontWeight: 'bold' }}>
							{'O'}
						</Col>
					</Row>
					{!isMobile && (
						<Checkbox
							style={{ marginTop: 10 }}
							checked={tradeModalStore?.skip}
							onChange={e => {
								mutateTradeModalStore({
									...tradeModalStore,
									skip: e.target.checked,
								})
							}}>
							{t('notagain')}
						</Checkbox>
					)}
				</Modal>
				{!isMobile && (
					<>
						<CButton
							title={t('limit')}
							onClick={() => {
								if (tradeModalStore?.skip) {
									sendOrder('L')
								} else {
									setLimitMarket('L')
									setOpen(true)
								}
							}}
							style={{
								background: COLORS.secondary,
								color: COLORS.white,
								fontSize: 10,
								whiteSpace: 'nowrap',
								paddingBlock: 5,
							}}
						/>
						<CButton
							title={t('market')}
							onClick={() => {
								if (tradeModalStore?.skip) {
									sendOrder('M')
								} else {
									setLimitMarket('M')
									setOpen(true)
								}
							}}
							style={{
								background: COLORS.primary,
								whiteSpace: 'nowrap',
								color: COLORS.white,
								fontSize: '1rem',
								paddingBlock: 5,
							}}
						/>
					</>
				)}
				{isMobile ? (
					<></>
				) : (
					// <InputMobile
					// 	defaultValue={item?.price}
					// 	value={closePrice}
					// 	type="number"
					// 	style={{
					// 		width: isMobile ? '100%' : 95,
					// 		color: textw,
					// 		fontSize: '1rem',
					// 		marginLeft: 5,
					// 	}}
					// 	onChange={e => setClosePrice(e)}
					// 	step={parseFloat('0.' + '1'.padStart(EP?.dp || 3, '0'))}
					// />
					<Input
						defaultValue={item?.price}
						value={closePrice}
						type="number"
						style={{
							width: isMobile ? '100%' : 95,
							fontSize: '1.1rem',
							marginLeft: 5,
						}}
						onChange={e => setClosePrice(e.target.value)}
						size={isMobile ? 'middle' : 'small'}
						step={parseFloat('0.' + '1'.padStart(EP?.dp || 3, '0'))}
					/>
				)}

				<Popover
					content={
						<Space.Compact size="small">
							<Button
								size="small"
								onClick={() => {
									setAmount(
										BigNumber(item?.amount)
											.multipliedBy(0.25)
											.multipliedBy(item?.leverage)
											.toNumber()
									)
								}}>
								25%
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(
										BigNumber(item?.amount)
											.multipliedBy(0.5)
											.multipliedBy(item?.leverage)
											.toNumber()
									)
								}}>
								50%
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(
										BigNumber(item?.amount)
											.multipliedBy(0.75)
											.multipliedBy(item?.leverage)
											.toNumber()
									)
								}}>
								75%
							</Button>
							<Button
								size="small"
								onClick={() => {
									setAmount(
										BigNumber(item?.amount)
											.multipliedBy(item?.leverage)
											.toNumber()
									)
								}}>
								100%
							</Button>
						</Space.Compact>
					}
					title=""
					trigger="click">
					{isMobile ? (
						<></>
					) : (
						// <InputMobile
						// 	defaultValue={item?.amount}
						// 	value={amount}
						// 	type="number"
						// 	step={0.001}
						// 	style={{
						// 		color: textw,
						// 		width: isMobile ? '100%' : 95,
						// 		fontSize: '1rem',
						// 		marginLeft: 5,
						// 	}}
						// 	onChange={e => setAmount(e)}
						// />
						<Input
							defaultValue={item?.amount}
							value={amount}
							type="number"
							step={0.001}
							style={{
								width: isMobile ? '100%' : 95,
								fontSize: '1.1rem',
								marginLeft: 5,
							}}
							onChange={e => setAmount(e.target.value)}
							size={isMobile ? 'middle' : 'small'}
						/>
					)}
				</Popover>

				{/* <TradeText style={{ whiteSpace: 'nowrap', width: 160 }}>
            {useTimezone(item?.created_at)}
        </TradeText> */}
				<MView
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						flex: 1,
						width: '100%',
						whiteSpace: 'nowrap',
						marginLeft: 10,
					}}>
					<>
						{!isMobile && item?.tpsl?.length > 0 && (
							<View
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start',
									fontSize: 10,
									marginRight: 5,
								}}>
								<Text>TP: {item?.tpsl?.[0]?.tp || '-'}</Text>
								<Text>SL: {item?.tpsl?.[0]?.sl || '-'}</Text>
							</View>
						)}
					</>

					<>
						{isMobile && item?.tpsl?.length > 0 && (
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									fontSize: 10,
									marginRight: 5,
									width: '100%',
								}}>
								<View
									style={{
										flexDirection: 'row',
										flex: 1,
										width: '100%',
									}}>
									<Text>
										TP:{' '}
										{item?.tpsl?.[0]?.tp
											? item?.tpsl?.[0]?.tp +
											  ' ' +
											  currency
											: '-'}
									</Text>
									{item?.tpsl?.[0]?.tp && (
										<Text
											style={{
												marginLeft: 10,
												paddingInline: 5,
											}}>
											(
											<Text style={{ marginRight: 5 }}>
												{t('pnlprice') + ': '}
											</Text>
											<KrwPrice
												price={new BigNumber(
													+item?.price || 1
												)
													.minus(+item?.tpsl?.[0]?.tp)
													.multipliedBy(+item?.amount)
													.multipliedBy(
														+item?.leverage
													)
													.toNumber()}
											/>
											)
										</Text>
									)}
								</View>
								<View
									style={{
										flexDirection: 'row',
										flex: 1,
										width: '100%',
									}}>
									<Text>
										SL:{' '}
										{item?.tpsl?.[0]?.sl
											? item?.tpsl?.[0]?.sl +
											  ' ' +
											  currency
											: '-'}
									</Text>
									{item?.tpsl?.[0]?.sl && (
										<Text
											style={{
												marginLeft: 10,
												paddingInline: 5,
											}}>
											(
											<Text style={{ marginRight: 5 }}>
												{t('pnlprice') + ': '}
											</Text>
											<KrwPrice
												price={new BigNumber(
													+item?.price || 1
												)
													.minus(+item?.tpsl?.[0]?.sl)
													.multipliedBy(+item?.amount)
													.multipliedBy(
														+item?.leverage
													)
													.toNumber()}
											/>
											)
										</Text>
									)}
								</View>
							</View>
						)}
					</>

					{!isMobile && (
						<TPSLEDIT
							posid={item?.id}
							tpsl={item?.tpsl?.[0]}
							price={item?.price}
							amount={item?.amount}
							long_short={item?.long_short}
							leverage={item?.leverage}
							margin={item?.margin}
							isMobile={false}
						/>
					)}
				</MView>
			</View>
			{isMobile && (
				<View
					style={{
						width: '100%',
						flexDirection: 'row',
						gap: 3,
						marginTop: 1,
					}}>
					<CButton
						title={t('closeBy')}
						onClick={() => {
							setLimitMarket('M')
							setOpen(true)
						}}
						style={{
							background: COLORS.gray,
							color: COLORS.white,
							fontSize: 10,
							minHeight: 30,
							width: isMobile ? '100%' : 'auto',
							borderRadius: 3,
							whiteSpace: 'nowrap',
							paddingBlock: 5,
						}}
					/>
					<TPSLEDIT
						posid={item?.id}
						tpsl={item?.tpsl?.[0]}
						price={item?.price}
						amount={item?.amount}
						long_short={item?.long_short}
						leverage={item?.leverage}
						margin={item?.margin}
						isMobile={true}
					/>
				</View>
			)}
		</View>
	)
}

export default ClosedBy
