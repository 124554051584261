import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { View } from 'styles/reactnative'
import { DepositLeftSectionWrapper } from '../styles'
// import DepositAutoChanneled from './DepositAutoChanneled'
import DepositChainType from './DepositChainType'
// import DepositQrCode from './DepositQrCode'
import DepositWalletAddress from './DepositWalletAddress'
import WithdrawWalletAddress from './WithdrawWalletAddress'
import { useDepositStore } from '../../../hooks/deposit/store'
import DepositCoins from '../../DepositModal/DepositLeftSection/DepositCoins'
import { Button, Col, Input, InputNumber, Row, Space, message } from 'antd'
import { ThemeContext } from '../../../../theme/ThemeProvider'
import { useFetcherPublic } from '../../../hooks/fetcher'
import { useToken } from '../../../hooks'
import BigNumber from 'bignumber.js'
import { IconSend } from '@tabler/icons'
import API from '../../../../utils/api'
import { useBalance } from '../../../hooks/user/mybalance'
import { useMyProfile } from '../../../hooks/user/myprofile'

const WithdrawLeftSection = ({ width = '60%', setShowModal = e => {} }) => {
	const { textw } = useContext(ThemeContext).theme.COLORS
	const [data, setData] = React.useState('')

	const { data: depositStore } = useDepositStore({
		network: 'TRC20',
		coin: 'USDT',
	})

	const [refresh, setRefresh] = useState(false)
	const currencyList = useFetcherPublic('/system/currency')

	const equivusd = currencyList?.data?.result?.result?.find(
		(item: any) => item?.code === 'KRW'
	)

	const { data: balance } = useBalance('USDT', 0)

	const { data: token } = useToken()
	const [amount, setAmount] = useState(0)
	const [name, setName] = useState('')

	const { data: myprofile } = useMyProfile()
	const { data: balanceData, mutate } = useBalance('KRW', 9)

	let available = BigNumber(balanceData?.balance).minus(
		balanceData?.lockbalance
	)

	useEffect(() => {
		setTimeout(() => {
			setRefresh(!refresh)
		}, 500)
	}, [depositStore?.coin, depositStore?.network])

	return (
		<DepositLeftSectionWrapper style={{ width: width }}>
			<View
				style={{
					paddingInline: 15,
					paddingBlock: 10,
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					width: '100%',
					height: '100%',
					alignSelf: 'flex-start',
				}}>
				<View
					style={{
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						width: '100%',
						alignSelf: 'flex-start',
					}}>
					<DepositCoins />
					{depositStore?.coin == 'KRW' ? (
						<Space
							style={{
								flexDirection: 'column',
								alignItems: 'flex-start',
								marginBottom: 20,
							}}>
							<Row align={'middle'}>
								<Col span={6} style={{ color: textw }}>
									Request Amount
								</Col>
								<Col span={13}>
									<InputNumber
										value={amount}
										formatter={value =>
											`${value}`
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													','
												)
												?.replace(/[^0-9,]/g, '')
										}
										parser={(value: any) =>
											value!
												.replace(/\$\s?|(,*)/g, '')
												?.replace(/[^0-9]/g, '')
										}
										step={10}
										onChange={(e: any) =>
											setAmount(
												Math.min(
													parseInt(
														available.toNumber() +
															''
													),
													+BigNumber(e).toFixed(0)
												)
											)
										}
										style={{
											textAlign: 'right',
											width: '100%',
											flex: 1,
										}}
									/>
								</Col>
								<Col span={1} style={{ color: textw }}>
									$
								</Col>
								<Col span={4}>
									<Button
										size="small"
										style={{ padding: 0, marginLeft: 5 }}
										onClick={() => {
											setAmount(0)
										}}>
										RESET
									</Button>
								</Col>
							</Row>
							<Row align={'middle'}>
								<Col span={24}>
									<Space.Compact size={'small'}>
										<Button
											size="small"
											onClick={() => {
												const max = new BigNumber(
													BigNumber(
														balance?.balance || 0
													).minus(
														balance?.lockbalance ||
															0
													)
												)
													.minus(1)
												setAmount(max.toNumber())
											}}>
											+MAX
										</Button>
										<Button
											size="small"
											onClick={() => {
												setAmount(amount + 1000)
											}}>
											+1000$
										</Button>
										<Button
											size="small"
											onClick={() => {
												setAmount(amount + 500)
											}}>
											+500$
										</Button>
										<Button
											size="small"
											onClick={() => {
												setAmount(amount + 100)
											}}>
											+100$
										</Button>
										<Button
											size="small"
											onClick={() => {
												setAmount(amount + 5)
											}}>
											+5$
										</Button>
										<Button
											size="small"
											onClick={() => {
												setAmount(amount + 1)
											}}>
											+1$
										</Button>
									</Space.Compact>
								</Col>
							</Row>
							{/* <Row align={'middle'}>
								<Col span={6} style={{ color: textw }}>
									환산금액
								</Col>
								<Col
									span={18}
									style={{
										flexDirection: 'row',
										display: 'flex',
										alignItems: 'center',
									}}>
									<Input
										type="text"
										style={{
											flex: 1,
											color: new BigNumber(amount)
												.dividedBy(equivusd?.price)
												?.isGreaterThan(
													BigNumber(
														balance?.balance || 0
													).minus(
														balance?.lockbalance ||
															0
													)
												)
												? COLORS.darkRed
												: COLORS.primary,
										}}
										value={new BigNumber(amount)
											.dividedBy(equivusd?.price)
											.toFixed(2)}
										readOnly></Input>{' '}
									<div style={{ color: textw }}>USDT</div>
								</Col>
							</Row> */}
							<Row align={'middle'}>
								<Col span={6} style={{ color: textw }}>
									Avaliable Amount
								</Col>
								<Col
									span={18}
									style={{
										flexDirection: 'row',
										display: 'flex',
										alignItems: 'center',
									}}>
									<Input
										type="text"
										style={{ flex: 1 }}
										value={new BigNumber(
											balance?.balance || 0
										)
											.minus(balance?.lockbalance || 0)
											.toFormat(2)}
										readOnly></Input>{' '}
									<div style={{ color: textw }}>USDT</div>
								</Col>
							</Row>
							<span style={{ color: 'orange', fontSize: 12 }}>
								* Available amount is calculated based on the Spot Wallet balance.
							</span>
							{/* <Row align={'middle'}>
								<Col span={6} style={{ color: textw }}>
									환율
								</Col>
								<Col span={18}>
									<Input
										type="text"
										value={equivusd?.price?.toFixed(2)}
										readOnly></Input>
								</Col>
							</Row> */}
						</Space>
					) : (
						<DepositChainType />
					)}

					<WithdrawWalletAddress data={data} setData={setData} />

					<Button
						type="primary"
						icon={<IconSend size={14} />}
						style={{ width: '100%', flex: 1, marginTop: 10 }}
						onClick={() => {
							if (
								myprofile?.level == 6 ||
								myprofile?.level == -8
							) {
								message.error(
									'Temporary users cannot withdraw money.'
								)
							} else {
							}
							if (!amount) {
								message.error('Request Amount is required.')
								return
							}

							if (
								new BigNumber(amount)
									.dividedBy(equivusd?.price)
									.isGreaterThan(
										new BigNumber(
											balance?.balance || 0
										).minus(balance?.lockbalance || 0)
									)
							) {
								message.error('Insufficient balance.')
								return
							}

							API.post('/cash/request', token + '', {
								type: 'W',
								amount: new BigNumber(amount)
									.dividedBy(equivusd?.price)
									.toFixed(2),
								bankaccount: data || 'Temp user',
								name,
							}).then(res => {
								if (res?.result?.success) {
									message.success(
										'Requested withdrawal successfully.'
									)
								} else {
									message.error(
										res?.result?.message ||
											'Failed to request withdrawal.'
									)
								}

								setShowModal(false)
							})
						}}>
						Withdraw
					</Button>
				</View>
			</View>
		</DepositLeftSectionWrapper>
	)
}

export default WithdrawLeftSection
