import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { IconCopy } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { View, Text } from 'styles/reactnative'
import { useDepositStore } from '../../../../hooks/deposit/store'
import { useFetcher, useFetcherSetting } from '../../../../hooks/fetcher'
import { useToken, useWindowDimensions } from 'app/hooks'
import { copyTextToClipboard, isIos } from '../../../../../utils/ciutils'
import { useMyProfile } from '../../../../hooks/user/myprofile'
import { Button, message } from 'antd'
import API from '../../../../../utils/api'

const DepositWalletAddress = () => {
	const { width } = useWindowDimensions()
	const { data: token } = useToken()
	const { t } = useTranslation()

	const { data: depositStore } = useDepositStore({
		network: 'TRC20',
		coin: 'KRW',
	})

	const { data: myprofile } = useMyProfile()

	const { data: res } = useFetcher(
		depositStore?.coin == 'KRW'
			? null
			: `/user/my/wallet?coin=${depositStore?.coin}&network=${depositStore?.network}`
	)

	const vaccount = myprofile?.vaccount?.replace(/undefined/g, '')

	let bank = vaccount?.split('/')?.[0] || ''
	let account = vaccount?.split('/')?.[1] || ''
	let name = vaccount?.split('/')?.[2] || ''

	const walletAddress =
		depositStore?.coin == 'KRW'
			? vaccount
			: res?.result?.result?.wallet_address || ''

	if (depositStore?.coin != 'KRW') {
		bank = ''
		name = ''
		account = walletAddress
	}

	const handleCopyClick = () => {
		toast(`${t('copiedSuccessfully')}`)

		copyTextToClipboard(
			depositStore?.coin == 'KRW' ? account : walletAddress
		)
	}

	return (
		<View
			style={{
				width: '100%',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<Text>
				{t('walletAddress')}
			</Text>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					border: `1px solid ${COLORS.secondary}`,
					width: '100%',
					borderRadius: 5,
					marginTop: 10,
				}}>
				{depositStore?.coin == 'KRW' && !myprofile?.vaccount ? (
					<></>
				) : (
					<>
						<View
							style={{
								width: '88%',
								flexDirection:
									depositStore?.coin == 'KRW'
										? 'row'
										: 'column',
								alignItems: 'flex-start',
								paddingInline: 10,
								paddingBlock: 15,
							}}>
							{bank && (
								<>
									<Text
										size={width > 900 ? 12 : 13}
										style={{
											color: COLORS.secondary,
											paddingRight: 10,
										}}>
										{bank}
									</Text>
									<View style={{ marginRight: 10 }}>/</View>
								</>
							)}
							<Text
								size={width > 900 ? 12 : 13}
								style={{ color: COLORS.secondary }}>
								{depositStore?.coin == 'KRW'
									? account
									: walletAddress}
							</Text>
							{name && (
								<>
									<View style={{ marginLeft: 10 }}>/</View>
									<Text
										size={width > 900 ? 12 : 13}
										style={{
											color: COLORS.secondary,
											paddingLeft: 10,
										}}>
										{name}
									</Text>
								</>
							)}
						</View>
						<CButton
							onClick={handleCopyClick}
							icon={<IconCopy />}
							style={{
								width: '12%',
								height: '100%',
								paddingBlock: 10,
								background: COLORS.white,
							}}
						/>
					</>
				)}
			</View>
		</View>
	)
}

export default DepositWalletAddress
