import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { DepositRightSectionWrapper } from './styles'
import { useDepositStore } from '../../hooks/deposit/store'
import { useFetcherSetting } from '../../hooks/fetcher'
import { Button } from 'antd'
import { MView } from '../../../styles/mobilePageStyles'
import { goTo } from '../../hooks/navigation'
import { useHistory } from 'react-router-dom'

const DepositRightSection = () => {
	const { textsilver, descBox, textw, bgColor } =
		useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	const nav = useHistory()

	const { data: depositStore } = useDepositStore({
		network: 'TRC20',
		coin: 'KRW',
	})
	const { data } = useFetcherSetting('CINFOD')
	return (
		<DepositRightSectionWrapper>
			<View
				style={{
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					background: `${descBox}`,
					marginRight: 30,
					padding: 10,
					height: '95%',
					overflow: 'hidden',
				}}>
				<MView
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						alignContent: 'center',
						width: '100%',
					}}>
					<Text size={16}>{t('important')}</Text>
					<Button
						type="primary"
						style={{}}
						size="small"
						onClick={() => {
							goTo(nav, '/supports?tab=2')
						}}>
						{t('Manual')}
					</Button>
				</MView>
				{depositStore?.coin == 'KRW' ? (
					<div
						style={{
							width: '100%',
							height: 450,
							overflowY: 'auto',
							overflowX: 'hidden',
							border: '1px solid #cdcdcd',
							lineHeight: 1.2,
							padding: 5,
							background: bgColor,
							color: textw,
							marginTop: 10,
							whiteSpace: 'pre-wrap',
						}}>
						-
					</div>
				) : (
					<View style={{ paddingBlock: 8, paddingInline: 5 }}>
						<Text
							size={12}
							style={{ color: textsilver, lineHeight: 1.2 }}>
							{t('depositFirstDesc')}
						</Text>
						<Text
							size={12}
							style={{
								color: textsilver,
								marginTop: 15,
								lineHeight: 1.2,
							}}>
							{t('depositSecondDesc')}
						</Text>
						<Text
							size={12}
							style={{
								color: textsilver,
								marginTop: 15,
								lineHeight: 1.2,
							}}>
							{t('depositThirdDesc')}
						</Text>
						<Text
							size={12}
							style={{
								color: textsilver,
								marginTop: 15,
								lineHeight: 1.2,
							}}>
							{t('depositFourthDesc')}
						</Text>
						<Text
							size={12}
							style={{
								color: textsilver,
								marginTop: 15,
								lineHeight: 1.2,
							}}>
							{t('depositFifthDesc')}
						</Text>
					</View>
				)}
			</View>
		</DepositRightSectionWrapper>
	)
}

export default DepositRightSection
