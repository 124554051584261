/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { useChartDataFeed } from '../../../../../hooks/trade/chartDataFeed'
import { useTradeModalStore } from '../../../../../hooks/trade/modalStore'
import { useTradeSocket } from '../../../../../hooks/trade/tradeSocket'
import useTradeStore from '../../../../../hooks/trade/tradeStore'
import { widgetOptions } from './ChartUtiles'
import { ChartContainerWrapper } from './styles'
import { useTradePosition } from '../../../../../hooks/trade/tradePosition'
import BigNumber from 'bignumber.js'
import { COLORS } from '../../../../../constants'
import { useFetcher } from '../../../../../hooks/fetcher'
import { useToken } from '../../../../../hooks'
import { SOCKET_URL } from '../../../../../constants/APIKEYS'

declare global {
	interface Window {
		Datafeeds: any
	}
}

let orderlines: any = []
const ChartContainer = ({ tab, isDragging }) => {
	const CHART_PARAM_STORAGE = 'TVWIDGET'

	const { bgColor } = useContext(ThemeContext).theme.COLORS
	const { isDarkMode } = useContext(ThemeContext)
	const ref = useRef<any>(null)
	const tvWidgetRef = useRef<any>(null)
	const { t, i18n } = useTranslation()
	const { data: token } = useToken()

	const { data: tradeModalStore, mutate: muateTradeModalStore } =
		useTradeModalStore()

	const { data: position, orderlist: list } = useTradePosition()

	const [loading, setLoading] = React.useState(true)

	const { data: updatechart, mutate: mutateUpdateChart } = useChartDataFeed()

	const { ep_id, pairs } = useTradeStore()
	const { data: socket, mutate: muateSocket } = useTradeSocket(SOCKET_URL)
	const loadStudy = () => {
		const savedStr = localStorage.getItem(CHART_PARAM_STORAGE)
		if (savedStr) {
			try {
				const saved = JSON.parse(savedStr)
				if (!saved) {
					return
				}

				if (saved.studyTemplate) {
					tvWidgetRef.current
						.activeChart()
						.applyStudyTemplate(saved.studyTemplate)
				}
			} catch (e) {}
		}

		tvWidgetRef.current.subscribe('onAutoSaveNeeded', function () {
			console.log('onAutoSaveNeeded SAVED2')
			tvWidgetRef.current.save(function (savedContent) {
				const savedTemplate = tvWidgetRef.current
					.activeChart()
					.createStudyTemplate({ saveInterval: false })

				localStorage.setItem(
					CHART_PARAM_STORAGE,
					JSON.stringify({
						studyTemplate: savedTemplate,
						content: savedContent,
					})
				)
			})
		})
		muateSocket()
	}

	useEffect(() => {
		if (!tvWidgetRef.current) return
		tvWidgetRef.current.subscribe('onAutoSaveNeeded', function () {
			console.log('onAutoSaveNeeded SAVED1')
			tvWidgetRef.current.save(function (savedContent) {
				const savedTemplate = tvWidgetRef.current
					.activeChart()
					.createStudyTemplate({ saveInterval: true })

				localStorage.setItem(
					CHART_PARAM_STORAGE,
					JSON.stringify({
						studyTemplate: savedTemplate,
						content: savedContent,
					})
				)
			})
		})
	}, [tvWidgetRef.current])

	//메인차트
	const loadchart = () => {
		setLoading(true)
		const tvWidget = new (window as any).TradingView.widget(
			widgetOptions(
				tvWidgetRef,
				pairs,
				ep_id,
				updatechart,
				mutateUpdateChart,
				tab,
				i18n.language,
				isDarkMode
			)
		)
		tvWidgetRef.current = tvWidget

		function createHeaderButton(text, title, clickHandler, options?) {
			var button = tvWidgetRef?.current?.createButton(options)
			button.setAttribute('title', title)
			button.textContent = text
			button.addEventListener('click', () => {
				clickHandler(button)
			})
		}

		tvWidgetRef.current.headerReady().then(function () {
			createHeaderButton('RESET', 'RESET', function () {
				localStorage.removeItem(CHART_PARAM_STORAGE)
				loadchart()
			})
		})

		setTimeout(() => {
			setLoading(false)
			loadStudy()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, 500)
	}

	useEffect(() => {
		if (!tvWidgetRef.current) return
		const res = tab?.includes('m') ? tab?.split('m')[0] : tab?.toUpperCase()

		if (tvWidgetRef?.current && tab) {
			if (tvWidgetRef?.current) {
				tvWidgetRef?.current?.onChartReady(() => {
					tvWidgetRef?.current?.chart().setResolution(res)
				})
				//tvWidgetRef?.current?.activeChart()
			}
		}
	}, [tab])

	useEffect(() => {
		setInterval(() => {
			if (!tvWidgetRef.current) return
		}, 1000)
	}, [])

	useEffect(() => {
		if (!tvWidgetRef.current) return
		tvWidgetRef?.current?.onChartReady(() => {
			for (const orderline of orderlines) {
				orderline?.line?.remove()
				setTimeout(() => {
					orderline?.line?.remove()
				}, 100)
			}
			orderlines = []

			if (tradeModalStore?.showpos) {
				list?.map(item => {
					if (item?.limit_market === 'M') return

					if (orderlines.find(orderline => orderline.id == item?.id))
						return

					const orderLine = tvWidgetRef?.current
						?.chart()
						?.createOrderLine()

					orderlines.push({ id: item?.id, line: orderLine })

					orderLine
						.setText(
							t('active') +
								':' +
								item?.leverage +
								'X: ' +
								(item.long_short === 'L'
									? t('long')
									: t('short'))
						)
						// 점선
						.setLineWidth(1)

						.setBodyTextColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)
						.setBodyBorderColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)
						.setQuantityBorderColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)
						.setQuantityBackgroundColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)

						.setLineColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)
						.setQuantity(
							BigNumber(item.amount)
								.multipliedBy(item?.leverage)
								.toFormat(0)
						)
						.setPrice(item?.order_price)
				})

				position?.map(item => {
					//TPSL미체결
					if (item?.tpsl && tvWidgetRef?.current?.chart()) {
						const TPSL = item?.tpsl?.[0]

						if (TPSL) {
							if (+TPSL?.tp != 0) {
								if (
									!orderlines.find(
										orderline =>
											orderline.id == 'TP' + item?.id
									)
								) {
									const orderLine = tvWidgetRef?.current
										?.chart()
										?.createOrderLine()

									orderlines.push({
										id: 'TP' + item?.id,
										line: orderLine,
									})

									const LONGSHORT =
										item?.long_short == 'L' ? 'S' : 'L'

									orderLine
										.setText(
											t('TP') +
												':' +
												item?.leverage +
												'X: ' +
												(LONGSHORT === 'L'
													? t('long')
													: t('short'))
										)
										.setBodyTextColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)
										.setBodyBorderColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)
										.setQuantityBorderColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)
										.setQuantityBackgroundColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)

										.setLineColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)
										.setLineStyle(2)
										.setQuantity(
											BigNumber(item?.amount)
												.multipliedBy(item?.leverage)
												.toFormat(0)
										)
										.setPrice(+TPSL?.tp)
								}
							}
							if (+TPSL?.sl != 0) {
								if (
									!orderlines.find(
										orderline =>
											orderline.id == 'SL' + item?.id
									)
								) {
									const orderLine = tvWidgetRef?.current
										?.chart()
										?.createOrderLine()

									orderlines.push({
										id: 'SL' + item?.id,
										line: orderLine,
									})

									const LONGSHORT =
										item?.long_short == 'L' ? 'S' : 'L'

									orderLine
										.setText(
											t('SL') +
												':' +
												item?.leverage +
												'X: ' +
												(LONGSHORT === 'L'
													? t('long')
													: t('short'))
										)
										.setBodyTextColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)
										.setBodyBorderColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)
										.setQuantityBorderColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)
										.setQuantityBackgroundColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)

										.setLineColor(
											LONGSHORT === 'L'
												? COLORS.lightGreen
												: COLORS.lightRed
										)
										.setLineStyle(2)
										.setQuantity(
											BigNumber(item?.amount)
												.multipliedBy(item?.leverage)
												.toFormat(0)
										)
										.setPrice(+TPSL?.sl)
								}
							}
						}
					}

					if (orderlines.find(orderline => orderline.id == item?.id))
						return

					const orderLine = tvWidgetRef?.current
						?.chart()
						?.createPositionLine()

					orderlines.push({ id: item?.id, line: orderLine })

					orderLine
						.setText(
							item?.leverage +
								'X: ' +
								(item.long_short === 'L'
									? t('long')
									: t('short'))
						)

						.setBodyTextColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)
						.setBodyBorderColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)
						.setQuantityBorderColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)
						.setQuantityBackgroundColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)

						.setLineColor(
							item.long_short === 'L'
								? COLORS.lightGreen
								: COLORS.lightRed
						)
						.setQuantity(
							BigNumber(item.amount)
								.multipliedBy(item?.leverage)
								.toFormat(0)
						)
						.setPrice(item?.price)
				})
			}
		})
	}, [position, tradeModalStore?.showpos, tvWidgetRef.current, list])

	useEffect(() => {
		loadchart()
	}, [ep_id, pairs, i18n.language, isDarkMode, tradeModalStore?.showpos])

	const initSocket = () => {
		if (!updatechart?.updatebars) return
		muateSocket(updatechart)
		loadStudy()
	}

	useEffect(() => {
		initSocket()
	}, [updatechart?.updatebars, tradeModalStore?.showpos])

	return (
		<ChartContainerWrapper>
			<View
				ref={ref}
				id={'tv_chart_container'}
				className={'TVChartContainer'}
				style={{
					width: '100%',
					height: '100%',
					background: bgColor,
				}}></View>
			{isDragging && (
				<View
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(255,255,255,0.1)',
						zIndex: 999,
					}}></View>
			)}
		</ChartContainerWrapper>
	)
}

export default ChartContainer
