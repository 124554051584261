import React, { useContext, useEffect, useState } from 'react'
import { useFetcherPublic } from '../../../../hooks/fetcher'

import OrderContainerNavBar from './OrderContainerNavBar'
import OrderContainerTable from './OrderContainerTable'
import OrderContainerTabs from './OrderContainerTabs'
import { OrderContainerSection } from './styles'
import TableAPI from '../../../../components/TableAPI'
import EntryTime from '../../../../components/EntryTime'
import { Text, View } from '../../../../../styles/reactnative'
import { Button, DatePicker, Select, Space, Tag } from 'antd'
import moment from 'moment'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { ThemeContext } from '../../../../../theme/ThemeProvider'
import { useTranslation } from 'react-i18next'
import { useExchangeStore } from '../../../../hooks/trade/exchangeStore'
import KrwPrice from '../../../../components/KrwPrice'
import TradePositionsTable from '../../../Trade/TradeDetail/TradePositionsTable'
import BigNumber from 'bignumber.js'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const OrderContainer = ({ cTab, setCTab, cTabs }) => {
	const now = moment()

	const yesterday = moment().add(-1, 'days')

	const { theme } = useContext(ThemeContext)
	const { t } = useTranslation()

	const { EPs } = useExchangeStore()

	const [startDate, setStartDate] = useState<any>(
		yesterday.format('YYYY-MM-DD')
	)
	const [endDate, setEndDate] = useState<any>(
		now.add(1, 'days').format('YYYY-MM-DD')
	)

	const [status, setStatus] = useState<any>('*')
	const [ep_id, setEP] = useState<any>('*')
	const [ordertype, setOrderType] = useState<any>('')

	const [open_close, setOpenClose] = useState<any>('*')

	useEffect(() => {
		if (cTab == 'orderhistory') {
			setStatus('*')
			setOpenClose('*')
		} else if (cTab == 'closedpnl') {
			setStatus('2')
			setOpenClose('C')
		} else if (cTab == 'HASPOSITIONS') {
			setStatus('1')
			setOpenClose('O')
		}
	}, [cTab])

	if (cTab == 'HASPOSITIONS') {
		return (
			<OrderContainerSection>
				<TradePositionsTable parentHeight={600} />
			</OrderContainerSection>
		)
	}

	let url = `/trade/orderlist?ep_id=${ep_id}&status=${status}&type=1&open_close=${open_close}&ordertype=${ordertype}
		${(startDate && `&sdate=${startDate}`) || ''}
		${(endDate && `&edate=${endDate}`) || ''}&t=
		`

	const eplist = EPs?.map(ep => {
		return {
			label: ep.pairs,
			value: +ep.id,
		}
	})

	return (
		<OrderContainerSection>
			<TableAPI
				title={
					<View
						style={{
							color: theme.COLORS.textw,
							flexDirection: 'row',
							justifyContent: 'flex-start',
						}}>
						<Select
							options={[
								{ label: t('all'), value: '*' },
								...eplist,
							]}
							style={{ marginRight: 10, minWidth: 200 }}
							value={ep_id}
							defaultValue={'*'}
							onChange={e => {
								setEP(e)
							}}
						/>
						{' '}
						<Space.Compact style={{ padding: '10px 0' }}>
							<DatePicker
								onChange={e => {
									const temp: any = e
									setStartDate(
										e ? e?.format('YYYY-MM-DD') : null
									)
								}}
								defaultValue={
									startDate
										? dayjs(startDate, 'YYYY-MM-DD')
										: undefined
								}
							/>
							<DatePicker
								onChange={e => {
									const temp: any = e
									setEndDate(
										e ? e?.format('YYYY-MM-DD') : null
									)
								}}
								defaultValue={
									endDate
										? dayjs(endDate, 'YYYY-MM-DD')
										: undefined
								}
							/>
							<Button
								onClick={() => {
									setStartDate('')
									setEndDate('')
								}}>
								All
							</Button>
							<Select
								value={ordertype}
								onChange={e => {
									setOrderType(e)
								}}
								style={{ minWidth: 150 }}>
								<Select.Option value="">Recent</Select.Option>
								<Select.Option value="1">
									Older
								</Select.Option>
								<Select.Option value="2">
									Trade Desc.
								</Select.Option>
								<Select.Option value="3">
									Trade Asc.
								</Select.Option>
								<Select.Option value="4">
									Pairs Desc.
								</Select.Option>
							</Select>
						</Space.Compact>
					</View>
				}
				defaultMaxData={20}
				stylewidth={'100%'}
				height={'default'}
				width={500}
				columns={() => [
					{
						title: t('orderTime'),
						dataIndex: 'created_at',
						key: 'created_at',
						align: 'center',
						width: 80,
						render: (text, data) => {
							return (
								<EntryTime
									time={text}
									format={'YYYY-MM-DD HH:mm:ss'}
								/>
							)
						},
					},
					{
						title: t('status'),
						dataIndex: 'open_close',
						key: 'open_close',
						align: 'center',
						width: 50,
						render: (text, item) => {
							return (
								<Tag
									color={
										item?.status == 2
											? 'blue'
											: item?.status == 1
											? 'gold'
											: 'red'
									}>
									{item?.status == 2
										? t('filled')
										: item?.status == 1
										? t('active')
										: t('cancel')}
								</Tag>
							)
						},
					},
					{
						title: t('pairs'),
						dataIndex: 'content',
						key: 'content',
						align: 'center',
						width: 80,
						render: (text, item) => {
							return (
								<View style={{ flexDirection: 'row' }}>
									<Tag
										color={
											item?.cross_iso == 'C'
												? 'blue'
												: 'red'
										}>
										{item?.cross_iso === 'C'
											? t('cross')
											: t('iso')}
										{item?.leverage}X
									</Tag>
									<Text>
										{
											EPs?.find(
												ep =>
													ep.id ===
													item?.exchange_pairs_id
											)?.pairs
										}
									</Text>
								</View>
							)
						},
					},
					{
						title: t('tradeType'),
						dataIndex: 'open_close',
						key: 'open_close',
						align: 'center',
						width: 50,
						render: (text, item) => {
							return (
								<View style={{ flexDirection: 'row' }}>
									{item?.force == 1 && (
										<Tag color={'orange'}>Liq.</Tag>
									)}
									<Tag
										color={
											item?.long_short === 'L'
												? 'green'
												: 'red'
										}>
										{item?.long_short == 'L'
											? t('long')
											: t('short')}
									</Tag>
									{item?.limit_market && (
										<Tag
											color={
												item?.limit_market === 'L'
													? 'blue'
													: 'red'
											}>
											{item?.limit_market === 'L'
												? t('limit')
												: t('market')}
										</Tag>
									)}
									{item?.open_close && (
										<Tag
											color={
												item?.open_close === 'O'
													? 'blue'
													: 'red'
											}>
											{item?.open_close === 'O'
												? t('open')
												: t('close')}
										</Tag>
									)}
								</View>
							)
						},
					},
					{
						title: t('orderPrice'),
						dataIndex: 'orderPrice',
						key: 'orderPrice',
						align: 'center',
						render: (text, item) => {
							return (
								<KrwPrice
									price={
										item?.trade_price || item?.order_price
									}
									equiv={true}
									color={false}
								/>
							)
						},
					},
					{
						title: t('orderQty'),
						dataIndex: 'amount',
						key: 'amount',
						align: 'center',
						render: (text, item) => {
							const EP = EPs?.find(
								ep => ep.id === item?.exchange_pairs_id
							)
							return (
								<KrwPrice
									price={new BigNumber(item?.amount)
										?.multipliedBy(item?.leverage)
										?.toFixed(EP?.dp || 2)}
									krw={false}
									margin={false}
									dp={2}
									color={false}
								/>
							)
						},
					},
					{
						title: t('margin'),
						dataIndex: 'margin',
						key: 'margin',
						align: 'center',
						render: (text, item) => {
							return (
								<KrwPrice
									price={item?.margin?.toFixed(2)}
									krw={true}
									equiv={true}
									dp={2}
									color={false}
								/>
							)
						},
					},
					{
						title: t('realized'),
						dataIndex: 'realized',
						key: 'realized',
						align: 'center',
						render: (text, item) => {
							return (
								<KrwPrice
									price={+item?.pnl_price?.toFixed(2)}
									krw={true}
									dp={2}
									equiv={true}
								/>
							)
						},
					},
					{
						title: t('fee'),
						dataIndex: 'fee',
						key: 'fee',
						align: 'center',
						render: (text, item) => {
							return (
								<KrwPrice
									price={
										-Math.abs(+item?.fee_price?.toFixed(2))
									}
									krw={true}
									dp={2}
									equiv={true}
								/>
							)
						},
					},
				]}
				apikey={url + '&t='}
			/>
		</OrderContainerSection>
	)
}

export default OrderContainer
