import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { IconDots } from '@tabler/icons'
import { TradeNavBarButton } from 'app/components/Trade'
import { NormalDropdown } from 'app/components/Common/'
import {
	TradeNavBarContainer,
	TradeNavBarRight,
	TradeNavBarRightLists,
	TradeNavBarRightListsMobile,
} from './styles'
import { COLORS } from 'app/constants/STYLES'
import { useWindowDimensions } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import useTradeStore from '../../../hooks/trade/tradeStore'
// import { useIndexPrice } from '../../../hooks/trade/indexprice'
import { useInfo24H } from '../../../hooks/trade/info24H'
import { ThemeContext } from 'theme/ThemeProvider'
import { getSetting, useSetting } from '../../../hooks/user/mysetting'
import { SETTING_TYPE_SYSTEM } from '../../../constants/SETTING'
import { useFetcherPublic } from '../../../hooks/fetcher'
import { goTo } from '../../../hooks/navigation'
import { useHistory } from 'react-router-dom'
import { useIndexPrice } from '../../../hooks/trade/indexprice'
import { Button, Col, Input, Modal, Row, Select, Slider, Space } from 'antd'
import { View } from '../../../../styles/reactnative'
import { useExchangeStore } from '../../../hooks/trade/exchangeStore'
import KrwPrice from '../../../components/KrwPrice'

const TradeNavBar = ({ toggleNavBar = () => {} }) => {
	const navigation = useHistory()
	const {
		bgColor,
		textw,
		textsilver,
		opacityBgColor,
		cardOpacity,
		content,
		descBox,
		stroke,
	} = React.useContext(ThemeContext).theme.COLORS
	const { t } = useTranslation()
	//t('tradingBot'),
	const marginData = t('marginData')
	const userGuide = t('userGuide')
	const calc = t('calculator')
	const tradesettings = t('tradesettings')
	const rightDropdownList = [userGuide, calc, tradesettings]
	const { width } = useWindowDimensions()
	const [selected, setSelected] = useState(tradesettings)

	const { data: tradeStore } = useTradeStore()
	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const settingCurrency = getSetting(setting, 'CURRENCY', 'USD')

	const currencyList = useFetcherPublic('/system/currency')
	const equiv = currencyList?.data?.result?.result?.find(
		(item: any) => item.code === settingCurrency
	)

	const { EPs } = useExchangeStore()

	//////////PNL

	const [calcEP, setCalcEP] = useState<any>(tradeStore?.ep_id)
	const [EP, setEP] = useState<any>({})
	const [tab1, setTab1] = useState('PNL')
	const [pnl1, setPnl1] = useState('LONG')
	const [pnl2, setPnl2] = useState(0) // 진입
	const [pnl3, setPnl3] = useState(0) //종료
	const [pnl4, setPnl4] = useState(0) //수량
	const [pnlleverage, setPnlLeverage] = useState(20)
	let leveragemarks = {
		1: '1x',
	}

	const maxleverage = EPs?.find(
		(item: any) => item.id == calcEP
	)?.max_leverage
	const split6 = Math.floor(maxleverage / 6)

	for (let i = 1; i < 6; i++) {
		leveragemarks[split6 * i] = split6 * i + 'x'
	}
	leveragemarks[maxleverage] = maxleverage + 'x'
	const [calcView, setCalcView] = useState(false)

	let pnlcalc_margin = new BigNumber(pnl4)
		.dividedBy(pnlleverage)
		.multipliedBy(pnl2)

	let pnlprice = new BigNumber(pnl3).minus(pnl2).multipliedBy(pnl4)
	if (pnl1 == 'SHORT') pnlprice = pnlprice.multipliedBy(-1)

	let MA = 0
	let MMR = 0.005
	const size = new BigNumber(pnl4).toNumber() //수량 x 레버리지
	const WB = new BigNumber(pnl3).toNumber() //마진
	const value = new BigNumber(pnl2).multipliedBy(size).toNumber() //가격pnl2 x 사이즈

	let coin = EP?.pairs?.split('/')?.[0]

	//BTC
	if (coin === 'BTC') {
		if (value < 50000) {
			MA = 0
			MMR = 0.004
		} else if (value < 250000) {
			MA = 50
			MMR = 0.005
		} else if (value < 3000000) {
			MA = 1300
			MMR = 0.01
		} else if (value < 20000000) {
			MA = 46300
			MMR = 0.025
		} else {
			MA = 546300
			MMR = 0.05
		}
	} else if (coin === 'ETH') {
		if (value < 100000) {
			MA = 0
			MMR = 0.005
		} else if (value < 250000) {
			MA = 150
			MMR = 0.0065
		} else if (value < 20000000) {
			MA = 1025
			MMR = 0.01
		} else if (value < 150000000) {
			MA = 21025
			MMR = 0.02
		} else if (value < 30000000) {
			MA = 471025
			MMR = 0.05
		} else {
			MA = 1971025
			MMR = 0.1
		}
	} else if (coin === 'XRP') {
		if (value < 5000) {
			MA = 0
			MMR = 0.005
		} else if (value < 10000) {
			MA = 5
			MMR = 0.0065
		} else if (value < 50000) {
			MA = 45
			MMR = 0.01
		} else if (value < 750000) {
			MA = 545
			MMR = 0.02
		} else if (value < 3000000) {
			MA = 23045
			MMR = 0.05
		} else {
			MA = 173045
			MMR = 0.1
		}
	} else if (coin === 'BCH') {
		if (value < 5000) {
			MA = 0
			MMR = 0.005
		} else if (value < 10000) {
			MA = 7.5
			MMR = 0.0065
		} else if (value < 50000) {
			MA = 42.5
			MMR = 0.01
		} else if (value < 500000) {
			MA = 542.5
			MMR = 0.02
		} else if (value < 2000000) {
			MA = 15542.5
			MMR = 0.05
		} else {
			MA = 115542.5
			MMR = 0.1
		}
	} else if (coin === 'LTC') {
		if (value < 5000) {
			MA = 0
			MMR = 0.005
		} else if (value < 10000) {
			MA = 5
			MMR = 0.006
		} else if (value < 50000) {
			MA = 45
			MMR = 0.01
		} else if (value < 750000) {
			MA = 545
			MMR = 0.02
		} else if (value < 3000000) {
			MA = 23045
			MMR = 0.05
		} else {
			MA = 173045
			MMR = 0.1
		}
	} else if (coin === 'EOS') {
		if (value < 5000) {
			MA = 0
			MMR = 0.005
		} else if (value < 10000) {
			MA = 5
			MMR = 0.006
		} else if (value < 50000) {
			MA = 45
			MMR = 0.01
		} else if (value < 250000) {
			MA = 545
			MMR = 0.02
		} else if (value < 1000000) {
			MA = 8045
			MMR = 0.05
		} else {
			MA = 58045
			MMR = 0.1
		}
	} else if (coin === 'XMR') {
		if (value < 50000) {
			MA = 0
			MMR = 0.01
		} else if (value < 250000) {
			MA = 750
			MMR = 0.025
		} else if (value < 1000000) {
			MA = 7000
			MMR = 0.05
		} else {
			MA = 57000
			MMR = 0.1
		}
	}

	const A = WB + MA - (pnl1 == 'LONG' ? 1 : -1) * size * pnl2
	const B = size * MMR - (pnl1 == 'LONG' ? 1 : -1) * size
	let liquid_price = new BigNumber(A).dividedBy(B).toNumber()

	useEffect(() => {
		if (!tradeStore?.ep_id) return
		setCalcEP(tradeStore?.ep_id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeStore?.ep_id])

	useEffect(() => {
		if (!calcEP) return
		// eslint-disable-next-line react-hooks/exhaustive-deps
		setEP(EPs?.find((item: any) => item.id == calcEP))
	}, [calcEP])

	useEffect(() => {
		if (!EP) return
		coin = EP?.pairs?.split('/')?.[0]
	}, [EP])
	//////////PNL

	const { data: info24H } = useInfo24H(tradeStore?.ep_id)

	let { getPrice } = useIndexPrice(tradeStore?.ep_id)

	const lastPrice = getPrice(tradeStore?.ep_id)

	const equivValue = BigNumber(lastPrice).multipliedBy(equiv?.price || 1)

	return (
		<TradeNavBarContainer style={{ background: bgColor }} stroke={stroke}>
			<Modal
				open={calcView}
				onCancel={() => setCalcView(false)}
				onOk={() => setCalcView(false)}
				title={t('calculator')}>
				<View
					style={{
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: 5,
					}}>
					<Select
						value={calcEP}
						onChange={(e: any) => {
							setCalcEP(e)
						}}
						style={{
							width: '100%',
							minWidth: 300,
						}}>
						{EPs?.map((item: any) => (
							<Select.Option value={item?.id}>
								{t(item?.name)} ({item?.pairs})
							</Select.Option>
						))}
					</Select>
					<Space.Compact size="middle" style={{ marginBottom: 20 }}>
						<Button
							type={tab1 == 'PNL' ? 'primary' : 'default'}
							onClick={() => {
								setTab1('PNL')
							}}>
							PNL
						</Button>
						<Button
							type={tab1 == 'LIQUID' ? 'primary' : 'default'}
							onClick={() => {
								setTab1('LIQUID')
							}}>
							{t('liquidation')}
						</Button>
					</Space.Compact>
					{/* PNL */}
					{tab1 == 'PNL' && (
						<View
							style={{
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: 5,
								width: '100%',
								borderTop: '1px solid #ddd',
								paddingTop: 15,
							}}>
							<Space.Compact size="small">
								<Button
									type={
										pnl1 == 'LONG' ? 'primary' : 'default'
									}
									onClick={() => {
										setPnl1('LONG')
									}}
									style={
										pnl1 == 'LONG'
											? {
													background:
														COLORS.lightGreen,
													minWidth: 100,
											  }
											: { minWidth: 100 }
									}>
									{t('long')}
								</Button>
								<Button
									type={
										pnl1 == 'SHORT' ? 'primary' : 'default'
									}
									onClick={() => {
										setPnl1('SHORT')
									}}
									style={
										pnl1 == 'SHORT'
											? {
													background: COLORS.lightRed,
													minWidth: 100,
											  }
											: { minWidth: 100 }
									}>
									{t('short')}
								</Button>
							</Space.Compact>
							<Space.Compact
								size="small"
								style={{
									width: '100%',
								}}>
								<Slider
									style={{
										width: '100%',
									}}
									min={1}
									max={
										EPs?.find(
											(item: any) => item.id == calcEP
										)?.max_leverage
									}
									defaultValue={pnlleverage}
									onChange={(e: any) => {
										setPnlLeverage(e)
									}}
									marks={leveragemarks}
								/>
							</Space.Compact>
							<Row style={{ width: '100%' }}>
								<Col span={6}>{t('entryPrice')}</Col>
								<Col span={18} style={{}}>
									<Input
										placeholder={t('entryPrice')}
										value={pnl2}
										step={0.000001}
										type="number"
										suffix="USDT"
										onChange={e => {
											setPnl2(+e.target.value)
										}}
									/>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={6}>{t('exitPrice')}</Col>
								<Col span={18} style={{}}>
									<Input
										placeholder={t('exitPrice')}
										value={pnl3}
										step={0.000001}
										type="number"
										suffix="USDT"
										onChange={e => {
											setPnl3(+e.target.value)
										}}
									/>
								</Col>
							</Row>
							<Row style={{ width: '100%' }}>
								<Col span={6}>{t('quantity')}</Col>
								<Col span={18} style={{}}>
									<Input
										placeholder={t('quantity')}
										value={pnl4}
										step={0.000001}
										type="number"
										suffix={coin}
										onChange={e => {
											setPnl4(+e.target.value)
										}}
									/>
								</Col>
							</Row>
							<Button type="primary">{t('calculate')}</Button>

							<View
								style={{
									width: '100%',
									background: descBox,
									color: textw,
									alignItems: 'flex-start',
									padding: 10,
									borderRadius: 10,
								}}>
								<Row style={{ width: '100%' }}>
									<Col span={6}>{t('margin')}</Col>
									<Col
										span={18}
										style={{ justifyItems: 'flex-start' }}>
										<View
											style={{
												alignItems: 'flex-start',
												justifyItems: 'flex-start',
												alignContent: 'flex-start',
												justifyContent: 'flex-start',
											}}>
											<KrwPrice
												price={pnlcalc_margin?.toNumber()}
												color={false}
												dp={3}
												krw={true}
												equiv={true}
												margin={false}
											/>
										</View>
									</Col>
								</Row>
								<Row style={{ width: '100%' }}>
									<Col
										span={6}
										style={{ justifyItems: 'flex-start' }}>
										{t('pnlprice')}
									</Col>
									<Col span={18} style={{}}>
										<View
											style={{
												alignItems: 'flex-start',
												justifyItems: 'flex-start',
												alignContent: 'flex-start',
												justifyContent: 'flex-start',
											}}>
											<KrwPrice
												price={pnlprice?.toNumber()}
												ratemargin={pnlcalc_margin?.toNumber()}
												equiv={true}
												krw={true}
												margin={false}
											/>
										</View>
									</Col>
								</Row>
							</View>
						</View>
					)}
				</View>
				{/* 청산가 */}
				{tab1 == 'LIQUID' && (
					<View
						style={{
							flexDirection: 'column',
							alignItems: 'flex-start',
							gap: 5,
							width: '100%',
							borderTop: '1px solid #ddd',
							paddingTop: 15,
						}}>
						<Space.Compact size="small">
							<Button
								type={pnl1 == 'LONG' ? 'primary' : 'default'}
								onClick={() => {
									setPnl1('LONG')
								}}
								style={
									pnl1 == 'LONG'
										? {
												background: COLORS.lightGreen,
												minWidth: 100,
										  }
										: { minWidth: 100 }
								}>
								{t('long')}
							</Button>
							<Button
								type={pnl1 == 'SHORT' ? 'primary' : 'default'}
								onClick={() => {
									setPnl1('SHORT')
								}}
								style={
									pnl1 == 'SHORT'
										? {
												background: COLORS.lightRed,
												minWidth: 100,
										  }
										: { minWidth: 100 }
								}>
								{t('short')}
							</Button>
						</Space.Compact>
						<Space.Compact
							size="small"
							style={{
								width: '100%',
							}}>
							<Slider
								style={{
									width: '100%',
								}}
								min={1}
								max={
									EPs?.find((item: any) => item.id == calcEP)
										?.max_leverage
								}
								defaultValue={pnlleverage}
								onChange={(e: any) => {
									setPnlLeverage(e)
								}}
								marks={leveragemarks}
							/>
						</Space.Compact>
						<Row style={{ width: '100%' }}>
							<Col span={6}>
								{t('entryPrice')} {coin}
							</Col>
							<Col span={18} style={{}}>
								<Input
									placeholder={t('entryPrice')}
									value={pnl2}
									step={0.000001}
									type="number"
									onChange={e => {
										setPnl2(+e.target.value)
									}}
									suffix="USDT"
								/>
							</Col>
						</Row>
						<Row style={{ width: '100%' }}>
							<Col span={6}>{t('margin')}</Col>
							<Col span={18} style={{}}>
								<Input
									placeholder={t('margin')}
									value={pnl3}
									step={0.000001}
									type="number"
									suffix="USDT"
									onChange={e => {
										setPnl3(+e.target.value)
									}}
								/>
							</Col>
						</Row>
						<Row style={{ width: '100%' }}>
							<Col span={6}>{t('qty')}</Col>
							<Col span={18} style={{}}>
								<Input
									placeholder={t('qty')}
									value={pnl4}
									step={0.000001}
									type="number"
									suffix={coin}
									onChange={e => {
										setPnl4(+e.target.value)
									}}
								/>
							</Col>
						</Row>
						<Button type="primary">{t('calculate')}</Button>

						<View
							style={{
								width: '100%',
								background: descBox,
								color: textw,
								alignItems: 'flex-start',
								padding: 10,
								borderRadius: 10,
							}}>
							<Row style={{ width: '100%' }}>
								<Col span={6}>{t('estimatedPrice')}</Col>
								<Col
									span={18}
									style={{ justifyItems: 'flex-start' }}>
									<View
										style={{
											alignItems: 'flex-start',
											justifyItems: 'flex-start',
											alignContent: 'flex-start',
											justifyContent: 'flex-start',
										}}>
										<KrwPrice
											price={liquid_price}
											color={false}
											dp={3}
											krw={true}
											equiv={true}
											margin={false}
										/>
									</View>
								</Col>
							</Row>
						</View>
					</View>
				)}
			</Modal>

			<TradeNavBarRight>
				<TradeNavBarRightLists>
					<TradeNavBarButton
						label={'userGuide'}
						icon="guide"
						onPress={() => {
							goTo(navigation, '/supports?tab=2')
						}}
					/>
					<TradeNavBarButton
						label={'calculator'}
						icon="calc"
						onPress={() => {
							setCalcView(true)
						}}
					/>
					<TradeNavBarButton
						label={'tradesettings'}
						icon="settings"
						onPress={() => toggleNavBar()}
					/>
				</TradeNavBarRightLists>
			</TradeNavBarRight>
		</TradeNavBarContainer>
	)
}

export default React.memo(TradeNavBar)
