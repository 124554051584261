import React, { useState, useContext } from 'react'
import { IconChevronDown } from '@tabler/icons'
import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text, View } from 'styles/reactnative'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'theme/ThemeProvider'

const TransferToDropdown = ({
	transferTo,
	setTransferTo,
	transferFrom,
	action,
}) => {
	const { t } = useTranslation()
	const { gray, textw } = useContext(ThemeContext).theme.COLORS
	const [display, setDisplay] = useState<boolean>(false)
	let list = ['spotwallet', 'futurewallet',]

	// if (transferFrom === 'globalwallet') {
	// 	setTransferTo('spotwallet')
	// }

	return (
		<View style={{ width: '45%' }}>
			<Text
				size={14}
				style={{
					paddingBottom: 5,
					alignSelf: 'flex-start',
				}}>
				{t('to')}
			</Text>
			<View
				style={{
					width: '100%',
					height: 35,
					background: gray,
					borderRadius: 5,
				}}>
				<CButton
					title={t(transferTo)}
					onClick={() => setDisplay(!display)}
					icon={<IconChevronDown size={10} color={textw} />}
					style={{
						height: '100%',
						width: '100%',
						color: textw,
						justifyContent: 'space-between',
					}}
				/>
				{display ? (
					<View
						style={{
							position: 'absolute',
							width: '41%',
							background: gray,
							zIndex: 99,
							marginTop: (list.length * 50) / (10 / list.length),
						}}>
						{list?.map((item, index) => (
							<CButton
								key={index.toString()}
								title={t(item)}
								onClick={() => {
									setTransferTo(item)
									action(item)
									setDisplay(!display)
								}}
								backgroundHover={COLORS.lightGray3}
								style={{
									width: '100%',
									alignItems: 'flex-start',
									padding: 10,
									cursor: 'pointer',
									color:
										transferTo === item
											? COLORS.secondary
											: COLORS.lightGray1,
								}}
							/>
						))}
					</View>
				) : null}
			</View>
		</View>
	)
}

export default TransferToDropdown
