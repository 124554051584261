import { IconEye, IconEyeOff } from '@tabler/icons'
import BigNumber from 'bignumber.js'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'styles/reactnative'
import { ThemeContext } from 'theme/ThemeProvider'
import { SETTING_TYPE_SYSTEM } from '../../../../constants/SETTING'
import { useFetcherPublic } from '../../../../hooks/fetcher'
import { useBalance } from '../../../../hooks/user/mybalance'
import { getSetting, useSetting } from '../../../../hooks/user/mysetting'
import { AssetsHeaderSection } from './styles'
import { Badge, Button } from 'antd'
import { useTradePosition } from '../../../../hooks/trade/tradePosition'
import { CButton } from '../../../../components/Common'
import { COLORS } from '../../../../constants'
import { useTradeModalStore } from '../../../../hooks/trade/modalStore'

const AssetsHeader = () => {
	BigNumber.clone()

	const { textsilver } = useContext(ThemeContext).theme.COLORS

	const { t } = useTranslation()
	const [visible, setVisible] = React.useState(false)

	const { spot, futures, global } = useTradePosition()

	const { data: setting } = useSetting(SETTING_TYPE_SYSTEM)
	const currency = getSetting(setting, 'CURRENCY', 'USD')

	const { data: result } = useFetcherPublic('/system/currency')
	const currentCurrency = result?.result?.result?.find(
		(item: any) => item.code === currency
	)

	const { data: modalStore, mutate: muatateModalStore } = useTradeModalStore()

	const total = BigNumber(spot?.balance || 0).plus(futures?.balance || 0)

	const equivalent = BigNumber(total)
		.multipliedBy(currentCurrency?.price || 1)
		.toFormat(0)

	const globalbalance = new BigNumber(global?.balance)
	const globalequiv = BigNumber(global?.balance).dividedBy(
		currentCurrency?.price || 1
	)

	return (
		<AssetsHeaderSection
			onClick={() => {
				console.log('You cliced to assetHeader')
			}}>
			<View style={{ flexDirection: 'row' }}>
				<Text size={16} style={{ color: textsilver, marginRight: 10 }}>
					{t('assetsOverview')}
				</Text>
				<Button
					onClick={() => setVisible(!visible)}
					style={{ padding: 0, margin: 0 }}
					size="small"
					icon={
						!visible ? (
							<IconEyeOff size={16} color={textsilver} />
						) : (
							<IconEye size={16} color={textsilver} />
						)
					}
					type="ghost"
				/>
			</View>
			<View style={{ flexDirection: 'row', marginTop: 20 }}>
				<Text size={16}>
					{visible
						? total.toFormat(3)
						: total?.toFormat(3).replace(/\d/g, '*')}
				</Text>
				<Text size={14} style={{ marginLeft: 10 }}>
					USDT
				</Text>
			</View>
			<View
				style={{
					flexDirection: 'row',
					marginTop: 10,
					marginBottom: 20,
				}}>
				<Text size={14}>
					≈ {visible ? equivalent : equivalent.replace(/\d/g, '*')}
				</Text>
				<Text size={14} style={{ marginLeft: 10 }}>
					{currency}
				</Text>
			</View>

			<Badge.Ribbon text={t('global')} color="gray" placement="start">
				<View
					style={{
						background: '#efefef',
						padding: 10,
						width: '100%',
						borderRadius: 10,
						marginTop: 20,
						paddingTop: 15,
					}}>
					<View style={{ flexDirection: 'row', marginTop: 5 }}>
						<Text size={13} style={{ color: '#555' }}>
							{isNaN(+globalbalance?.toNumber())
								? '0'
								: visible
								? globalbalance?.toFormat(0)
								: globalbalance
										?.toFormat(0)
										.replace(/\d/g, '*')}
						</Text>
						<Text
							size={13}
							style={{ color: '#555', marginLeft: 10 }}>
							KRW
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginTop: 5 }}>
						<Text size={12} style={{ color: '#555' }}>
							≈{' '}
							{isNaN(+globalequiv?.toNumber())
								? '0'
								: visible
								? globalequiv?.toFormat(3)
								: globalequiv?.toFormat(3).replace(/\d/g, '*')}
						</Text>
						<Text
							size={12}
							style={{ marginLeft: 10, color: '#555' }}>
							USDT
						</Text>
					</View>
				</View>
			</Badge.Ribbon>

			<CButton
				title={t('transfer')}
				textStyle={{ color: COLORS.black }}
				onClick={() => {
					muatateModalStore({
						...modalStore,
						transferModal: true,
					})
				}}
				// icon={<IconArrowBarToDown size={18} />}
				style={{
					borderWidth: 1,
					background: COLORS.secondary,
					color: COLORS.white,
					paddingBlock: 3,
					paddingInline: 8,
				}}
				iconLeft={true}
				textHover={COLORS.white}
			/>
		</AssetsHeaderSection>
	)
}

export default React.memo(AssetsHeader)
