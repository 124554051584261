import { CButton } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { goTo } from 'app/hooks/navigation'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { View, Text } from 'styles/reactnative'

const AboutJoinCrypto = () => {
	const navigation = useHistory()
	const { t } = useTranslation()
	return (
		<View style={{ width: '100%', marginTop: 20, marginBottom: 50 }}>
			<Text size={22}>{t('joinTheCryptoMovement')}</Text>
			<Text
				size={16}
				style={{
					marginTop: 8,
					lineHeight: 1.2,
					fontWeight: '300',
				}}>
				{t('joinTheCryptoMovementContent')}
			</Text>
			<CButton
				title={t('signUpNow')}
				onClick={() => {
					goTo(navigation, '/signup')
				}}
				style={{
					marginBlock: 20,
					color: COLORS.white,
					height: 35,
					paddingInline: 15,
					borderRadius: 3,
					background:
					'linear-gradient(to bottom, #385dff 0%, #387eff 100%)',
				}}
			/>
		</View>
	)
}

export default AboutJoinCrypto
